import companyGoals from './companyGoals'
import divGoals from './divGoals'
import consultDepartGoals from './consultDepartGoals'
import divStaffGoals from './divStaffGoals'
import counselStaffGoals from './counselStaffGoals'
export default [
    companyGoals,
    divGoals,
    consultDepartGoals,
    divStaffGoals,
    counselStaffGoals
]