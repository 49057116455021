<template>
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>

<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import { onMounted } from 'vue';
onMounted(() => {
  // getScreen(1920)
  // window.onresize = () => {
  //   getScreen(1920)
  // };
})

const getScreen = (val) => {
  let u = navigator.userAgent;
  if ((u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1)) {
  } else {
    let zoom = 0;
    zoom = window.innerWidth / val;
    document.getElementsByTagName('body')[0].style.zoom = zoom;
  }
}
</script>
