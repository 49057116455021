import axios from "axios";
import router from "@/router/index";
import {paramsSerializer} from "@/utils/core";

const baseURL = '/api';

const instance = axios.create({
  baseURL,
  timeout: 60000,
  paramsSerializer
});

instance.interceptors.response.use(response => response.data, async error => {
  const response = error.response;
  if (response) {
    // 需要授权登录
    if (response.status === 401) {
      await router.push('/login');
      return Promise.reject({
        message: '请先登录',
        status: response.status
      });
    }
    return Promise.reject(response.data.message != null
      ? response.data
      : {
        message: `${response.status}：${response.statusText}`,
        status: response.status
      }
    );
  }
  if (error.request) {
    return Promise.reject({message: '网络连接出错'}); // 请求失败（网络错误）
  }
  return Promise.reject({message: error.message}); // 其他错误
});

export function get(path, params) {
  return instance.get(path, {
    params
  });
}

export function post(path, data,config) {
  return instance.post(path, data instanceof FormData ? data : paramsSerializer(data),config);
}


export function postRequest(path, data, config) {
  return instance.post(path, data,config);
}
