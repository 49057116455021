export default {
  key: '/store/strategyInfo',
  title: '攻略资讯',
  permissionTit: '攻略资讯',
  config: {
      component: () => import("./views/strategyInfoDetail"),
      actions: [

      ],
      rowActions: [

      ]
  }
} 