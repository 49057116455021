export default {
    key: '/approvalCenter/paymentRefundReview',
    title: '付款退款复核',
    permission: '/institutions/audit/list',
    config: {
        component: () => import("./views/paymentRefundReviewDetail"),
        rowActions: [
            {
                key: 'detail',
                text: '订单详情',
                type: 'blank',
                permission: '/institutions/audit/details',
                config: {
                    title: '订单详情',
                    component: () => import("./views/orderDetail")
                }
            },
            {
                text: '列表通过',
                permission: '/institutions/audit/pass',
            },
            {
                text: '提成编辑',
                permission: '/institutions/audit/editCommission',
            },
        ],
        actions: [
            {
                key: 'edit',
                text: '通过',
                icon: 'plus',
                type: 'dialog',
                isSelected: true,  // 是否必勾选
                determine: true,  // 是否需要判定条
                permission: '/institutions/audit/pass',
                modalObj: (rowData, tableData) => {
                    return `您共选择 ${rowData.length} 条收支记录，是否全部通过? `
                },
                config: {
                    title: '审批通过',
                    submitUrl: '/institutions/audit/pass',
                    submitText: '确认',
                    controls: [
                        {
                            key: 'reviewResponse',
                            label: '审批回复',
                            type: 'textarea',
                            required: true,
                            config: {
                                rules: [
                                    { min: 1, max: 50, message: '只能输入1-50个字符' }
                                ]
                            }
                        },
                    ]
                }
            },
            {
                key: 'edit',
                text: '拒绝',
                icon: 'plus',
                type: 'dialog',
                isSelected: true,  // 是否必勾选
                determine: true,  // 是否需要判定条
                permission: '/institutions/audit/refuse',
                modalObj: (rowData, tableData) => {
                    return `您共选择 ${rowData.length} 条收支记录，是否全部拒绝?`
                },
                config: {
                    title: '审批拒绝',
                    submitUrl: '/institutions/audit/refuse',
                    submitText: '确认',
                    controls: [
                        {
                            key: 'reviewResponse',
                            label: '审批回复',
                            type: 'textarea',
                            required: true,
                            config: {
                                rules: [
                                    { min: 1, max: 50, message: '只能输入1-50个字符' }
                                ]
                            }
                        },
                    ]
                }
            },
        ],
    }

}

