export default {
  key: '/agency/reservation',
  title: '预约福利',
  permission: '/benefit/getList',
  permissionTit: '服务项目',
  config: {
    component: () => import("./views/reservation"),
    actions: [
      {
        text: '新建预约福利',
        permission: '/benefit/insert',
      },
      {
        text: '编辑福利介绍',
        permission: '/benefit/editBenefitPicture',
      },
      {
        text: '删除',
        permission: '/benefit/delete',
      }
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/benefit/edit',
      }
    ]
  }
} 