import qs from "qs";
import dayjs from "dayjs";

export function resolveValue(value, key) {
  return key.split('.').reduce((prev, key) => {
    return prev ? prev[key] : null
  }, value);
}

export function paramsSerializer(params) {
  if (params == null) {
    return '';
  }
  params = Object.keys(params).reduce((prev, key) => {
    const value = params[key];
    if (value != null && value !== '') {
      prev[key] = value;
    }
    return prev;
  }, {});
  return qs.stringify(params, {
    indices: false,
    allowDots: true,
    skipNulls: true
  });
}

export function mapBy(arr, key) {
  return arr.reduce((map, item) => {
    map[item[key]] = item;
    return map;
  }, {});
}

export function formatDate(value, format = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(value).format(format);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0B';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
