<template>
  <div v-if="!$isMobile" class="reset">
    <div>
      <img src="../assets/img/pc-login-logo.png" style="margin: 40px; width: 200px;" alt="">
    </div>
    <div class="login-body">
      <img src="../assets/img/pc-login-banner.png" style="width: 400px; margin-right: 200px;" alt="">
      <a-card class="lb-card">
        <div style="margin-bottom: 40px;">
          <LeftOutlined @click="back" style="font-size: 16px; color: #666666;" />
          <p style="font-size: 22px; font-weight: 600; margin-top: 20px;">重置密码</p>
          <p style="color: #666666; margin-top: 6px;">请重置至少6位字符的密码</p>
        </div>
        <a-form ref="form" layout="vertical" :model="form">
          <a-form-item label="" name="phoneNumber"
            :rules="[{ required: true, message: '输入手机号' }, { pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
            <a-input v-model:value.trim="form.phoneNumber" placeholder="输入手机号">
            </a-input>
          </a-form-item>
          <a-form-item label="" name="smsCode" :rules="[{ required: true, message: '输入验证码' }]">
            <div class="mr-ve">
              <a-input v-model:value.trim="form.smsCode" placeholder="输入验证码">
              </a-input>
              <span v-if="!validate" @click="getCountDown" class="re-code">获取验证码</span>
              <span v-else class="re-code-num">{{ countodwn }} S</span>
            </div>
          </a-form-item>
          <a-form-item label="" name="password"
            :rules="[{ required: true, message: '输入密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
            <a-input-password v-model:value.trim="form.password" placeholder="输入密码">
            </a-input-password>
          </a-form-item>
          <a-form-item label="" name="rePassword"
            :rules="[{ required: true, message: '输入确认密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
            <a-input-password v-model:value.trim="form.rePassword" placeholder="输入确认密码">
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <div style="display: flex; justify-content: center; margin-top: 40px;">
              <a-button class="start" block size="large" type="primary" @click="register">确定</a-button>
            </div>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </div>
  <div class="m-reset" v-else>
    <div style="padding: 20px;">
      <LeftOutlined @click="back" style="font-size: 16px; color: #666666;" />
      <p style="font-size: 22px; font-weight: 600; margin-top: 20px;">重置密码</p>
      <p style="color: #666666; margin-top: 6px;">请重置至少6位字符的密码</p>
    </div>
    <div style="padding: 30px 20px;">
      <a-form ref="form" layout="vertical" :model="form">
        <a-form-item label="" name="phoneNumber"
          :rules="[{ required: true, message: '输入手机号' }, { pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
          <a-input v-model:value.trim="form.phoneNumber" placeholder="输入手机号">
          </a-input>
        </a-form-item>
        <a-form-item label="" name="smsCode" :rules="[{ required: true, message: '输入验证码' }]">
          <div class="mr-ve">
            <a-input v-model:value.trim="form.smsCode" placeholder="输入验证码">
            </a-input>
            <span v-if="!validate" @click="getCountDown" class="re-code">获取验证码</span>
            <span v-else class="re-code-num">{{ countodwn }} S</span>
          </div>
        </a-form-item>
        <a-form-item label="" name="password"
          :rules="[{ required: true, message: '输入密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
          <a-input-password v-model:value.trim="form.password" placeholder="输入密码">
          </a-input-password>
        </a-form-item>
        <a-form-item label="" name="rePassword"
          :rules="[{ required: true, message: '输入确认密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
          <a-input-password v-model:value.trim="form.rePassword" placeholder="输入确认密码">
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <div style="display: flex; justify-content: center;">
            <a-button class="start" block size="large" type="primary" @click="register">确定</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "message",
  data() {
    return {
      form: {},
      countodwn: 59,
      validate: false,
      countIn: null
    }
  },
  destroyed() {
    this.countodwn = 59;
    this.validate = false;
    clearInterval(this.countIn);
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },

    async getCountDown() {
      if (!this.form.phoneNumber) {
        this.$message.error('请输入正确的手机号');
        return
      }
      clearInterval(this.countIn);
      this.countodwn = 59;
      this.validate = false
      try {
        let res = await this.$http.post('/auth/getSmsCode', {
          phoneNumber: this.form.phoneNumber
        })
        if (res.code == '200') {
          this.validate = true;
          this.countIn = setInterval(() => {
            if (this.countodwn > 1) {
              this.countodwn--
            } else {
              clearInterval(this.countIn);
              this.countodwn = 59;
              this.validate = false
            }
          }, 1000)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async register() {
      if (this.form.rePassword != this.form.password) {
        this.$message.error('两次输入密码不一致');
        return
      }
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      let { phoneNumber, password, smsCode, ref } = this.form;
      try {
        let res = await this.$http.post('/auth/resetPassword', { phoneNumber, password, smsCode });
        if (res.code == '200') {
          this.$message.success('重置成功');
          this.toLogin()
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    toLogin() {
      this.$router.push('/login')
    },
  }
}
</script>

<style lang="scss" scoped>
.reset {
  width: 100%;
  height: 100vh;
  background: url('../assets/img/pc-login-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .login-body {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .lb-card {
    width: 400px;
    height: 560px;
  }

  .ml-header {
    background: url('../assets/img/m-login-bg.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 180px;
    padding: 16px 0;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 8px 20px;
    border-radius: 40px;
  }

  .ant-btn.ant-btn-lg {
    border-radius: 40px;
  }

  .mr-ve {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 40px;

    .ant-input {
      padding: 0;
      border: none;
    }
  }

  .re-code {
    color: #ffffff;
    display: inline-block;
    width: 140px;
    font-size: 12px;
    text-align: center;
    margin-left: 12px;
    height: 30px;
    line-height: 30px;
    background-color: #175DFF;
    cursor: pointer;
    border-radius: 40px;
    border: none;
  }

  .re-code-num {
    @extend .re-code;
    background-color: #C8C8C8;
  }

}
</style>
<style lang="scss" scoped>
.m-reset {
  .ant-input-affix-wrapper {
    border-color: #DCDCDC;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 8px 20px;
    border-radius: 40px;
  }

  .ant-btn.ant-btn-lg {
    border-radius: 40px;
  }

  .mr-ve {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 40px;

    .ant-input {
      padding: 0;
      border: none;
    }
  }

  .re-code {
    color: #ffffff;
    display: inline-block;
    width: 140px;
    font-size: 12px;
    text-align: center;
    margin-left: 12px;
    height: 30px;
    line-height: 30px;
    background-color: #175DFF;
    cursor: pointer;
    border-radius: 40px;
    border: none;
  }

  .re-code-num {
    @extend .re-code;
    background-color: #C8C8C8;
  }
}
</style>
