<template>
  <div class="inline">
    <a-popconfirm :title="config.title ? config.title : `确定${text}?`" :ok-text="config.submitText"
      :ok-type="config.submitColor" cancel-text="取消" :disabled="disabledValue || submitLoading" @confirm="handleConfirm">
      <a-button v-if="type === 'button'" :disabled="disabledValue" :type="action.config.color" :icon="action.icon">{{ text
      }}
      </a-button>
      <a-button v-else-if="type === 'link'" type="link"
        :class="{ 'text-danger': !disabledValue ? action.config.color === 'danger' : '' }" :disabled="disabledValue">
        <!-- <a-icon v-if="action.icon" :type="action.icon"/> -->
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ text }}
      </a-button>
    </a-popconfirm>
  </div>
</template>

<script>

export default {
  name: "TableAction",
  props: ['type', 'action', 'selectedRows', 'disabledValue'],
  data() {
    return {
      submitLoading: false
    }
  },

  computed: {
    config() {
      return this.action.config;
    },
    text() {
      if (typeof this.action.text === 'function') {
        return this.action.text({
          selectedRows: this.selectedRows
        });
      }
      return this.action.text;
    },
    disabled() {
      if (this.action.disabled == null) {
        return false;
      }

      if (typeof this.action.disabled === 'function') {
        return this.action.disabled({
          selectedRows: this.selectedRows
        });
      }

      return this.action.disabled;
    }
  },

  methods: {
    async handleConfirm() {
      this.submitLoading = true;
      try {
        const response = await this.$http.post(this.action.config.submitUrl, {
          id: this.selectedRows.map(row => row.id),
        })
        if (typeof this.action.config.submitSuccessMessage === 'function') {
          let submitSuccessMessage = this.action.config.submitSuccessMessage;
          submitSuccessMessage = submitSuccessMessage({
            response
          });
          this.$message.success(submitSuccessMessage ?? (this.text + '成功'));
        } else {
          this.$message.success(this.action.config.submitSuccessMessage ?? (this.text + '成功'));
        }
        this.$emit('change');
      } catch ({ message }) {
        this.$message.error(message)
      }
      this.submitLoading = false;
    }
  }
}
</script>
