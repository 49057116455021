<template>
  <div>
    <a-card class="assign_con" style="padding: 0 30px;">
      <h1 style="margin-bottom: 50px; font-size: 30px;">个人中心</h1>
      <p style="margin-bottom: 20px;">机构名：{{ detail.orgName }}</p>
      <p v-if="!detail.phoneNumber || !detail.phoneNumberDesc" style="margin-bottom: 20px;">手机号(账号)：暂无</p>
      <p v-else style="margin-bottom: 20px;">手机号(账号)：{{ detail.desensitization == false ? detail.phoneNumber :
        detail.phoneNumberDesc }}</p>
      <p style="margin-bottom: 20px;">姓名：{{ detail.name || '' }}</p>
      <p style="margin-bottom: 20px;">所属部门：{{ detail.departmentName || '暂无' }}</p>
      <p style="margin-bottom: 20px;">角色：{{ detail.institutionsRoleName || '暂无' }}</p>
      <p style="margin-bottom: 20px;">列表联系方式查看：{{ detail.desensitization == false ? '明文显示' : '密文显示' }}</p>
      <div>
        <span>操作：</span>
        <span v-if="detail.phoneNumber" @click="changeBindingModal = true"
          style="color: #1677ff; cursor: pointer; margin-right: 20px;">换绑手机</span>
        <span v-else @click="bindingModel = true"
          style="color: #1677ff; cursor: pointer; margin-right: 20px;">绑定手机</span>
        <change-password />
      </div>
    </a-card>
    <a-modal title="换绑手机号" v-model:open="changeBindingModal">
      <div v-if="step == 1">
        <p style="margin-bottom: 20px;">当前绑定手机：{{ detail.desensitization == false ? detail.phoneNumber :
        detail.phoneNumberDesc }}</p>
        <a-form layout="vertical" ref="stepFormOne" :model="stepFormOne">
          <a-row>
            <a-col :span="24">
              <a-form-item name="smsCode" label="短信验证码" :rules="[{ required: true, message: '请输入验证码' }]">
                <div style="display: flex; align-items: center;">
                  <a-input v-model:value="stepFormOne.smsCode" placeholder="输入验证码" />
                  <span v-if="!validate" @click="getCountDown()" class="pc-re-code">获取验证码</span>
                  <span v-else class="pc-re-code-num">{{ countodwn }}</span>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <div style="display: flex; justify-content: flex-end;">
              <a-button type="primary" @click="vertify" style="margin-right: 20px;">下一步</a-button>
              <a-button @click="cancle()">取消</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div v-if="step == 2">
        <a-form layout="vertical" ref="stepFormTwo" :model="stepFormTwo">
          <a-row>
            <a-col :span="24">
              <a-form-item label="换绑手机号" name="newPhoneNumber"
                :rules="[{ pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
                <a-input v-model:value="stepFormTwo.newPhoneNumber" placeholder="输入手机号" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item name="newSmsCode" label="短信验证码" :rules="[{ required: true, message: '请输入验证码' }]">
                <div style="display: flex; align-items: center;">
                  <a-input v-model:value="stepFormTwo.newSmsCode" placeholder="输入验证码" />
                  <span v-if="!validateTwo" @click="getCountDownTwo()" class="pc-re-code">获取验证码</span>
                  <span v-else class="pc-re-code-num">{{ countodwnTwo }}</span>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <div style="display: flex; justify-content: flex-end;">
              <a-button type="primary" @click="changePhone" style="margin-right: 20px;">下一步</a-button>
              <a-button @click="cancle()">取消</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <template #footer class="dialog-footer">
      </template>
    </a-modal>

    <a-modal title="绑定手机号" v-model:open="bindingModel">
      <a-form layout="vertical" ref="form" :model="form">
        <a-row>
          <a-col :span="24">
            <a-form-item label="绑定手机号" name="newPhoneNumber"
              :rules="[{ required: true, message: '请输入绑定手机号' },{ pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]"
              :model="form" @finish="submit">
              <a-input v-model:value="form.newPhoneNumber" placeholder="输入手机号" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item name="newSmsCode" label="短信验证码" :rules="[{ required: true, message: '请输入验证码' }]">
              <div style="display: flex; align-items: center;">
                <a-input v-model:value="form.newSmsCode" placeholder="输入验证码" />
                <span v-if="!validateThree" @click="getCountDownThree()" class="pc-re-code">获取验证码</span>
                <span v-else class="pc-re-code-num">{{ countodwnThree }}</span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template #footer class="dialog-footer">
        <a-button type="primary" @click="saveAssign">确定</a-button>
        <a-button @click="cancelThree">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { get, post } from "@/utils/http";
import ChangePassword from "@/components/system/ChangePassword";
export default {
  name: 'personalcenter',
  components: { ChangePassword },
  data() {
    return {
      detail: {},
      countIn: null,
      countodwn: 59,
      validate: false,

      countInTwo: null,
      countodwnTwo: 59,
      validateTwo: false,

      countInThree: null,
      countodwnThree: 59,
      validateThree: false,

      bindingModel: false,
      changeBindingModal: false,
      form: {},
      step: 1,
      stepFormOne: {},
      stepFormTwo: {}
    };
  },
  destroyed() {
    clearInterval(this.countIn);
    this.countodwn = 59;
    this.validate = false;
    clearInterval(this.countInTwo);
    this.countodwnTwo = 59;
    this.validateTwo = false;
    clearInterval(this.countInThree);
    this.countodwnThree = 59;
    this.validateThree = false;
  },
  async mounted() {
    this.getDetail();
  },

  methods: {
    async getCountDown() {
      try {
        let res = await this.$http.post('/auth/getSmsCode', {
          phoneNumber: this.detail.phoneNumber
        })
        if (res.code == '200') {
          this.validate = true;
          this.countIn = setInterval(() => {
            if (this.countodwn > 1) {
              this.countodwn--
            } else {
              clearInterval(this.countIn);
              this.countodwn = 59;
              this.validate = false
            }
          }, 1000)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getCountDownTwo() {
      if (!this.stepFormTwo.newPhoneNumber) {
        this.$message.error('请输入电话号码');
        return
      }
      try {
        let res = await this.$http.post('/auth/getSmsCode', {
          phoneNumber: this.stepFormTwo.newPhoneNumber
        })
        if (res.code == '200') {
          this.validateTwo = true;
          this.countInTwo = setInterval(() => {
            if (this.countodwnTwo > 1) {
              this.countodwnTwo--
            } else {
              clearInterval(this.countInTwo);
              this.countodwnTwo = 59;
              this.validateTwo = false
            }
          }, 1000)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getCountDownThree() {
      if (!this.form.newPhoneNumber) {
        this.$message.error('请输入电话号码');
        return
      }
      try {
        let res = await this.$http.post('/auth/getSmsCode', {
          phoneNumber: this.form.newPhoneNumber
        })
        if (res.code == '200') {
          this.validateThree = true;
          this.countInThree = setInterval(() => {
            if (this.countodwnThree > 1) {
              this.countodwnThree--
            } else {
              clearInterval(this.countInThree);
              this.countodwnThree = 59;
              this.validateThree = false
            }
          }, 1000)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    cancle() {
      clearInterval(this.countIn);
      this.countodwn = 59;
      this.validate = false;
      clearInterval(this.countInTwo);
      this.countodwnTwo = 59;
      this.validateTwo = false;
      this.changeBindingModal = false;
    },

    cancelThree() {
      clearInterval(this.countInThree);
      this.countodwnThree = 59;
      this.validateThree = false;
      this.bindingModel = false;
    },

    async getDetail() {
      try {
        let res = await this.$http.get('/system/institutionsEmployee/getMyOwnInfo')
        if (res.code == '200') {
          this.detail = res.data;
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async vertify() {
      try {
        await this.$refs.stepFormOne.validate();
      } catch (e) { // 未通过表单验证
        return;
      }

      try {
        let res = await this.$http.post('/system/institutionsEmployee/checkSmsCode', {
          phoneNumber: this.detail.phoneNumber,
          smsCode: this.stepFormOne.smsCode
        })
        if (res.code == '200') {
          this.step = 2;
          this.countodwn = 59;
          this.validate = false;
          clearInterval(this.countIn);
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async changePhone() {
      try {
        await this.$refs.stepFormTwo.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      try {
        let res = await this.$http.post('/system/institutionsEmployee/replacePhone', {
          oldPhoneNumber: this.detail.phoneNumber,
          newPhoneNumber: this.stepFormTwo.newPhoneNumber,
          newSmsCode: this.stepFormTwo.newSmsCode
        })
        if (res.code == '200') {
          this.step = 1;
          this.countodwnTwo = 59;
          this.validateTwo = false;
          clearInterval(this.countInTwo);
          this.changeBindingModal = false;
          this.getDetail();
          this.$message.success('换绑成功');
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async saveAssign() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      try {
        let res = await this.$http.post('/system/institutionsEmployee/bindPhone', {
          newPhoneNumber: this.form.newPhoneNumber,
          newSmsCode: this.form.newSmsCode
        })
        if (res.code == '200') {
          this.countodwnThree = 59;
          this.validateThree = false;
          clearInterval(this.countInThree);
          this.bindingModel = false;
          this.getDetail();
          this.$message.success('绑定成功');
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.pc-re-code {
  color: #1677ff;
  display: inline-block;
  width: 140px;
  font-size: 12px;
  border: 1px solid #1677ff;
  text-align: center;
  margin-left: 12px;
  height: 30px;
  line-height: 28px;
  border-radius: 8px;
  cursor: pointer
}

.pc-re-code-num {
  @extend .pc-re-code;
  font-size: 14px;
  color: #999999;
  border: 1px solid #999999;
}
</style>