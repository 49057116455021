import tree, { disableNode } from "@/utils/tree";

export default {
  title: '部门管理',
  type: 'table',
  key: '/system/department',
  permission: '/system/institutionsDepartment/list',
  config: {
    dataUrl: '/system/institutionsDepartment/list',
    tree: 'parentId',
    pagination: false,
    filter: {
      controls: []
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['id'],
      /* 默认排序 */
      defaultSort: '-id'
    },
    columns: [
      {
        key: 'name',
        title: '部门',
        width: 150,
      },
      {
        key: 'departmentViewerList',
        title: '数据查看人',
        width: 150,
        type: 'textList',
        ckey: 'institutionsEmployee.name'
      },
      {
        key: 'remarks',
        title: '部门描述',
        width: 150,
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '60px' : '100px'
      }
    ],
    actions: [
      {
        key: 'add',
        text: '添加部门',
        icon: 'plus',
        type: 'form',
        permission: '/system/institutionsDepartment/add',
        config: {
          color: 'primary',
          title: '添加部门',
          initUrl: '/system/institutionsDepartment/add/get',
          submitUrl: '/system/institutionsDepartment/add',
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '部门名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 20, message: '只能输入1-20个字符' }
                ]
              }
            },
            {
              key: 'parentId',
              label: '上级部门',
              type: 'treeSelect',
              config: {
                options: 'departments'
              }
            },
            {
              key: 'departmentHeadId',
              label: '部门经理',
              type: 'select',
              config: {
                options: "employeeList"
              }
            },
            {
              key: 'departmentViewerId',
              label: '数据查看人',
              type: 'mSelect',
              config: {
                options: "employeeList"
              }
            },
            {
              key: 'remarks',
              label: '备注',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 50, message: '只能输入1-50个字符' }
                ]
              }
            },
          ]
        }
      }
    ],
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        icon: 'edit',
        type: 'form',
        permission: '/system/institutionsDepartment/edit',
        config: {
          color: 'primary',
          title: '修改部门',
          initUrl: "/system/institutionsDepartment/edit/get",
          submitUrl: "/system/institutionsDepartment/edit",
          submitText: '确认修改',
          controls: [
            {
              key: 'name',
              label: '部门名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 20, message: '只能输入1-20个字符' }
                ]
              }
            },
            {
              key: 'parentId',
              label: '上级部门',
              type: 'treeSelect',
              config: {
                options: 'departments'
              }
            },
            {
              key: 'departmentHeadId',
              label: '部门经理',
              type: 'select',
              config: {
                options: "employeeList"
              }
            },
            {
              key: 'departmentViewerId',
              label: '数据查看人',
              type: 'mSelect',
              config: {
                options: "employeeList"
              }
            },
            {
              key: 'remarks',
              label: '备注',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 50, message: '只能输入1-50个字符' }
                ]
              }
            },
          ]
        }
      },
    ]
  }
}
