export default {
    key: '/dataReport/dataReport',
    title: '数据报表',
    permission: '/dataReport/dataReport/attributionList',
    permissionTit: '归属人聚合报表',
    config: {
        component: () => import("./views/dataReportDetail"),
        actions: [
            {
                text: '跟进人聚合报表',
                permission: '/dataReport/dataReport/followupList',
            },
            {
                text: '时间维度聚合报表',
                permission: '/dataReport/dataReport/aggregateReport',
            },
            {
                text: '时间维度聚合报表导出',
                permission: '/dataReport/dataReport/export',
            },
        ],
    }
}