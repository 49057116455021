export default {
  key: '/fee/rules',
  title: '提成规则',
  type: 'table',
  permission: '/institutions/commission/rule/getCommissionRuleList',
  config: {
    dataUrl: '/institutions/commission/rule/getCommissionRuleList',
    filter: {
      controls: [
        {
          key: 'planRuleName',
          label: '方案名',
          type: 'text'
        },
        {}
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'detail',
        text: '新建提成规则',
        type: 'blank',
        permission: '/institutions/commission/rule/add',
        config: {
          title: '新建提成规则',
          component: () => import("./views/rulesDetail")
        }
      },
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: [''],
      /* 默认排序 */
      defaultSort: ''
    },
    columns: [
      {
        key: 'planRuleName',
        title: '方案名',
        width: '130px'
      },
      {
        key: 'personalType',
        title: '个人提成方式',
        type: 'textEnum',
        width: '130px',
        config: {
          constants: {
            '1': '固定比例',
            '2': '阶梯比例'
          }
        }
      },
      {
        key: 'departmentType',
        title: '部门经理提成方式',
        width: '130px',
        type: 'textEnum',
        config: {
          constants: {
            '0': '无提成',
            '1': '固定比例',
            '2': '阶梯比例'
          }
        }
      },
      {
        key: 'lastType',
        title: '上一级经理提成方式',
        width: '130px',
        type: 'textEnum',
        config: {
          constants: {
            '0': '无提成',
            '1': '固定比例',
            '2': '阶梯比例'
          }
        }
      },
      {
        key: 'adaptDepartments',
        title: '适用部门数',
        width: '130px',
        type: 'customizeText',
        customize: (row) => {
          if (row.adaptDepartments.indexOf(',') == -1) {
            return 1
          } else {
            let arr = row.adaptDepartments.split(',');
            return arr.length
          }
        }
      },
      {
        key: 'responsibleEmployeeName',
        title: '管理人',
        width: '130px'
      },
      {
        key: 'status',
        title: '使用状态',
        type: 'textEnum',
        popover: '启用的提成规则，会在每天凌晨自动计算之前的提成',
        width: '130px',
        config: {
          constants: {
            '0': '停用',
            '1': '启用'
          }
        }
      },
      {
        key: 'actions',
        title: '操作',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '200px',
        fixed: 'right'
      },
    ],

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '编辑',
        type: 'blank',
        permission: '/institutions/commission/rule/update',
        config: {
          title: '编辑',
          component: () => import("./views/editRules")
        }
      },
      {
        key: 'lock',
        text: '停用',
        type: 'confirm',
        permission: '/institutions/commission/rule/disable',
        disabled: ({ selectedRows }) => {
          return selectedRows[0].status === 1;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/institutions/commission/rule/disable'
        }
      },
      {
        key: 'unlock',
        text: '启用',
        type: 'confirm',
        permission: '/institutions/commission/rule/enable',
        disabled({ selectedRows }) {
          return selectedRows[0].status === 0;
        },
        config: {
          color: 'primary',
          submitColor: 'primary',
          submitUrl: '/institutions/commission/rule/enable'
        }
      }
    ]
  }
};