<template>
  <div>
    <div  v-if="digital" @click="reduction" style="cursor: pointer;">
    <span >{{ digital }}</span>
    </div>
    <span v-else class="text-muted">无</span>
  </div>

</template>

<script>
export default {
  props: {
    value:String,
    type:{
      type:String,
      default:"phoneNumber"
    }
  },
  name: "Desensitization.vue",
  data() {
    return{
    digital:''
    }
  },
  created() {
    this.digital=this.value;
    this.initData()
  },
  methods: {
    reduction(){
      this.digital=this.value;
    },
    initData() {
      if (!this.type || !this.value) {
        return;
      }
      const data=this.value.toString()
      if(this.type === "phoneNumber"){
        this.digital=data.substring(0, 4)+"****"+data.substring(8,data.length)
      }else if(this.type === "idCardNo"){
        this.digital=data.substring(0, 6)+"********"+data.substring(14,data.length)
      }
    }

  }
}
</script>

<style scoped>

</style>
