export default {
  key: '/system/tags',
  title: '标签管理',
  permission: '/system/labelManagement/list',
  config: {
    component: () => import("./views/businessDetail"),
    actions: [
      // {
      //   text: '新建客户标签',
      //   permission: '/system/labelManagement/customerLabel',
      // },
      // {
      //   text: '新建商机阶段',
      //   permission: '/system/labelManagement/opportunityStage',
      // },
      {
        text: '新建签单项目',
        permission: '/system/labelManagement/businessOpportunityProject',
      },
    ],

    rowActions: [
      {
        text: '编辑',
        permission: '/system/labelManagement/edit',
      },
      {
        text: '停用',
        permission: '/system/labelManagement/lock',
      },
      {
        text: '恢复',
        permission: '/system/labelManagement/unlock',
      }
    ],

  }
}

