import { receptionCity, country } from '@/utils/public';
export default {
  key: '/store/institutionsUserEvaluate',
  title: '用户评价',
  type: 'table',
  permission: '/clientUser/admin/getClientUserList',
  config: {
    dataUrl: '/clientUser/admin/getClientUserList',
    filter: {
      // initUrl: '/system/employee/list/get',
      controls: [
        {
          key: 'city',
          label: '机构',
          type: 'select',
          config: {
            options: country
          }
        }
      ]
    },

    actions: [
    ],

    columns: [

      {
        key: 'uid',
        width: '100px',
        title: '客户姓名'
      },
      {
        key: 'nickname',
        width: '100px',
        title: '机构'
      },
      {
        key: 'phoneNumber',
        width: '100px',
        title: '平均评分'
      },
      {
        key: 'uid',
        width: '100px',
        title: '专业度评分'
      },
      {
        key: 'nickname',
        width: '100px',
        title: '响应速度评分'
      },
      {
        key: 'phoneNumber',
        width: '100px',
        title: '服务态度评分'
      },
      {
        key: 'name',
        title: '简介',
        width: '200px',
        ellipsis: true
      },

      {
        key: 'images',
        width: '50px',
        type: 'preview',
        title: '查看',

      },
      
      {
        key: 'time',
        title: '注册时间',
        type: 'datetime',
        width: '200px',
      },
      // {
      //   key: 'name',
      //   title: '操作',
      //   fixed: 'right',
      //   type: 'actions',
      //   width: document.documentElement.clientWidth <= 768 ? '84px' : '300px'
      // }
    ],
    /* 表格排序 */
    sorter: {
      /* 默认排序 */
      defaultSort: ''
    },
    /* 表格行操作 */
    rowActions: [
      
    ]
  }
};
