
import bom from "./bom";
import system from "./system";
import customer from "./customer"
import order from "./order";
import approvalCenter from "./approvalCenter";
import dataReport from "./dataReport";
import fee from "./fee";
import store from "./store";
import missionGoals from "./missionGoals";
import postService from "./postService";
export default [
  ...bom,
  ...order,
  ...system,
  ...customer,
  ...approvalCenter,
  ...fee,
  ...store,
  ...dataReport,
  ...missionGoals,
  ...postService,
  {
    key: '/home',
    title: '首页',
    type: 'blank',
    config: {
      component: () => import("./Home")
    }
  }
].reduce((prev, page) => {
  prev[page.key] = page;
  return prev;
}, {});
