import { intentionLevel, serviceItems, customerStageNew } from '@/utils/public';
export default {
  title: '精准客资',
  type: 'table',
  key: '/bom/bomlist',
  permission: '/institutions/accurateCustomerFunding/list',
  config: {
    dataUrl: '/institutions/accurateCustomerFunding/list',
    filter: {
      initUrl: '/institutions/accurateCustomerFunding/list/get',
      controls: [

        // {
        //   key: 'opportunityNameContains',
        //   label: '商机名称',
        //   type: 'text'
        // },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'intentionLevelIs',
          label: '意向等级',
          type: 'mSelect',
          config: {
            options: intentionLevel,
          }
        },
        {
          key: 'signingItemIdIs',
          label: '预计签单项目',
          type: 'mSelect',
          config: {
            textKey: 'content',
            valueKey: 'id',
            options: "signingItemList",
          }
        },
        // {
        //   key: 'customerPhaseIs',
        //   label: '客户阶段',
        //   type: 'select',
        //   config: {
        //     options: customerStageNew,
        //   }
        // },
        {
          key: 'attributionIdIs',
          label: '归属人',
          type: 'select',
          config: {
            options: 'attributablePersonList'
          }
        },
        {
          key: 'followUpWithPeopleIdIs',
          label: '跟进人',
          type: 'select',
          config: {
            options: 'followUpPeopleList'
          }
        },
        {
          key: 'preciseTime',
          label: '转精准时间',
          type: 'dateRange'
        },
      ]
    },
    listFilter: {
      key: 'quickCheck',
      defaultValue: null,
      options: [
        {
          value: null,
          name: '全部'
        },
        {
          name: '合同谈判',
          value: 'CONTRACT_NEGOTIATION'
        },
        {
          name: '成交',
          value: 'DEAL'
        },
        {
          name: '流失',
          value: 'LOSS'
        }
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-preciseTime'
    },
    actions: [
      // {
      //   key: 'add',
      //   text: '新建商机',
      //   type: 'blank',
      //   permission: '/institutions/accurateCustomerFunding/add',
      //   config: {
      //     title: '新建商机',
      //     color: 'primary',
      //     component: () => import("./views/addOpportunityDetail")
      //   }
      // },
      {
        key: 'add',
        text: '新建精准客资',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/accurateCustomerFunding/add',
        config: {
          color: 'primary',
          title: '新建精准客资',
          initUrl: '/institutions/accurateCustomerFunding/add/get',
          submitUrl: '/institutions/accurateCustomerFunding/add',
          submitText: '确认添加',
          controls: [
            {
              key: 'institutionsCustomerRelationship',
              label: '客户名',
              type: 'remoteSelect',
              required: true,
              searchConfig: {
                submitUrl: '/institutions/accurateCustomerFunding/customerListAll',
                key: "nameContains",
                formkey: 'institutionsCustomerRelationshipId'
              },
            },
            {
              key: 'preciseTime',
              label: '转精准时间',
              type: 'date',
              tips: '出合同或上门的客资即精准客资',
              today: true,
              disabledDate: true,
              required: true,
            },
            {
              key: 'signingItemId',
              label: '预计签单项目',
              type: 'select',
              required: true,
              config: {
                options: "signingItemList",
                textKey: 'content'
              }
            },
            {
              key: 'estimatedSigningAmount',
              label: '预计签单金额',
              type: 'numeric',
              required: true,
              config: {
                format: 'rmby',
              }
            }
          ]
        }
      },
    ],
    columns: [
      {
        key: 'institutionsCustomerRelationship.name',
        title: '客户姓名',
        width: 150,
      },
      {
        key: 'institutionsCustomerRelationship.intentionLevel',
        title: '意向等级',
        width: 150,
        type: 'enum',
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            },
            null: {
              text: '无',
              color: 'grey'
            },
          }
        }
      },
      {
        key: 'signingItem.content',
        title: '预计签单项目',
        width: 150,
      },
      {
        key: 'estimatedSigningAmount',
        title: '预计签单金额(元)',
        width: 150,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'customerPhase',
        title: '客户阶段',
        width: 150,
        type: 'textEnum',
        config: {
          constants: {
            'CONTRACT_NEGOTIATION': '合同谈判',
            'DEAL': '成交',
            'LOSS': '流失'
          }
        }
      },
      {
        key: 'attribution.username',
        title: '归属人',
        width: 150,
      },

      {
        key: 'followUpWithPeople.username',
        title: '跟进人',
        width: 150,
      },

      {
        key: 'preciseTime',
        title: '转精准时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '70px' : '250px'
      }
    ],
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/institutions/accurateCustomerFunding/details',
        config: {
          title: '客户详情',
          component: () => import("./views/institutionsCustomerDetail")
        }
      },
      // {
      //   key: 'add',
      //   text: '编辑',
      //   icon: 'plus',
      //   type: 'form',
      //   permission: '/institutions/accurateCustomerFunding/edit',
      //   config: {
      //     color: 'primary',
      //     title: '编辑商机',
      //     initUrl: '/institutions/accurateCustomerFunding/edit/get',
      //     submitUrl: '/institutions/accurateCustomerFunding/edit',
      //     submitText: '确认',
      //     controls: [
      //       {
      //         key: 'opportunityStageId',
      //         label: '',
      //         type: 'step',
      //         config: {
      //           options: "opportunityStageList"
      //         }
      //       },
      //       {
      //         key: 'opportunityName',
      //         label: '商机名称',
      //         type: 'text',
      //         required: true,
      //         config: {
      //           rules: [
      //             { min: 1, max: 10, message: '只能输入1-10个字符' }
      //           ]
      //         }
      //       },
      //       {
      //         key: 'name',
      //         label: '相关客户',
      //         type: 'text',
      //         disabled: true,
      //         config: {
      //           showkey: 'institutionsCustomerRelationshipId'
      //         },
      //       },
      //       {
      //         key: 'businessOpportunityProjectId',
      //         label: '商机项目',
      //         type: 'select',
      //         config: {
      //           options: "businessOpportunityProjectList",
      //           textKey: 'content'
      //         }
      //       },
      //       {
      //         key: 'estimatedSigningAmount',
      //         label: '预计签单额',
      //         type: 'numeric',
      //         config: {
      //           format: 'rmb',
      //         }
      //       }
      //     ]
      //   }
      // },
      // {
      {
        key: 'add',
        text: '跟进',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/accurateCustomerFunding/addFollowUp',
        config: {
          color: 'primary',
          title: '新增跟进记录',
          initUrl: '/institutions/accurateCustomerFunding/addFollowUp/get',
          submitUrl: '/institutions/accurateCustomerFunding/addFollowUp',
          submitText: '确认添加',
          submitSuccessMessage: (submitSuccessMessage) => {
            if (submitSuccessMessage.response.msg) {
              return `${submitSuccessMessage.response.msg}`
            } else {
              return '操作成功'
            }
          },
          verify: {
            submitUrl: "/institutions/accurateCustomerFunding/addFollowUp",
            secondUrl: "/institutions/accurateCustomerFunding/addFollowUpToHighSeas",
            needId: false,
            text: '该客资意向等级已修改为无效，要把客资移入客户公海吗？',
            condition: (forms) => {
              if (forms.intentionLevel == 'INVALID') {
                return true
              } else {
                return false
              }
            }
          },
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
              required: true,
            },
            {
              key: 'followUpTime',
              label: '跟进时间',
              type: 'date',
              disabledDate: true,
              today: true,
              required: true,
            },
            {
              key: 'nextFollowUpTime',
              label: '下次跟进时间',
              type: 'date',
              showNow: true,
              required: false,
              config: {
              }
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'accurateCustomer',
              label: '是否出合同或上门',
              type: 'radio',
              required: true,
              tips: '出合同或上门的客资即精准客资',
              config: {
                options: [
                  {
                    text: '否',
                    value: false,
                    noshow: ({ source }) => {
                      return source == true;
                    }
                  },
                  {
                    text: '是',
                    value: true,
                  },
                ]
              }
            },
            {},
            {
              key: 'signingItemId',
              label: '预计签单项目',
              type: 'select',
              required: true,
              isAssociate: true,
              config: {
                options: "signingItemList",
                textKey: 'content',
                hidden({ selectedRows, form, initData }) {
                  if (form.accurateCustomer == true) {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'estimatedSigningAmount',
              label: '预计签单金额',
              type: 'numeric',
              required: true,
              isAssociate: true,
              config: {
                format: 'rmby',
                hidden({ selectedRows, form, initData }) {
                  if (form.accurateCustomer == true) {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'followUpContent',
              label: '跟进内容',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ],
              }
            },
          ]
        }
      },
      {
        key: 'add',
        text: '成单',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/accurateCustomerFunding/addOrder',
        needPermission: true,
        showPermission:(e)=>{
          if(e !== 1) return true
        },
        disabled: ({ selectedRows }) => {
          return selectedRows[0].institutionsCustomerRelationship.status != 'NORMAL';
        },
        config: {
          color: 'primary',
          title: '新增订单',
          initUrl: '/institutions/accurateCustomerFunding/addOrder/get',
          submitUrl: '/institutions/accurateCustomerFunding/addOrder',
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'signingTime',
              label: '签单时间',
              type: 'date',
              disabledDate: true,
              showNow: true,
              required: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              type: 'select',
              required: true,
              config: {
                options: "signingItemList",
                textKey: 'content'
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              required: true,
              config: {
                format: 'currency',
                max: (form) => { }
              }
            },
            {
              key: 'attributionId',
              label: '订单归属人',
              type: 'select',
              required: true,
              config: {
                options: 'attributablePersonList'
              }
            },
            {
              key: 'followUpWithPeopleId',
              label: '订单跟进人',
              type: 'select',
              required: false,
              authRequired: {
                institution: 1,
                type: true
              },
              config: {
                options: 'followUpPeopleIdList'
              }
            },
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
    ]
  }
}
