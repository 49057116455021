import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../pages/MainLayout';
import TablePage from '@/components/TablePage';
import NotFound from '@/pages/NotFound';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import Reset from '@/pages/Reset';
import Welcome from '@/pages/Welcome';
import config from '@/config';
import Nprogress from 'nprogress';
import { getProfile } from "@/utils/session";
import message from '@/pages/msg/message';
import msgDetail from '@/pages/msg/msgDetail';
import PersonalCenter from '@/pages/PersonalCenter';
let type;
if(window.location.href == 'https://liufucrm.com/' || window.location.href == 'https://liufucrm.com') {
    type = 1
} else {
    if(window.location.href.indexOf('www') > 0) {
        type = 1
    } else {
        type = 2
    }
}
const routes = [
    {
        path: '/',
        component: type == 1 ? Welcome : Login,
        meta: {
            title: type == 1 ? '' : '登录'
        }
    },
    // {
    //     path: '/',
    //     component: Welcome,
    //     meta: {
    //         title: ''
    //     }
    // },
    {
        path: '/welcome',
        component: Welcome,
        meta: {
            title: ''
        }
    },
    {
        path: '/login',
        component: Login,
        meta: {
            title: '登录'
        }
    },
    {
        path: '/register',
        component: Register,
        meta: {
            title: '注册'
        }
    },
    {
        path: '/reset',
        component: Reset,
        meta: {
            title: '忘记密码'
        }
    },

    {
        path: '/home',
        component: MainLayout,
        children: [
            ...Object
                .keys(config.pages)
                .map(key => {
                    const page = config.pages[key];
                    return {
                        path: key,
                        meta: {
                            title: page.title
                        },
                        component: page.type === 'table' ? {
                            components: { TablePage },
                            render() {
                                return <table-page config={page.config} />;
                            }
                        } : page.config.component
                    };
                }),
            {
                path: '*',
                component: NotFound
            },
            {
                path: '/message',
                name: 'message',
                component: message,
                meta: {
                    title: '消息'
                }
            },
            {
                path: '/msgdetail',
                name: 'msgDetail',
                component: msgDetail,
                meta: {
                    title: '消息'
                }
            },
            {
                path: '/personalcenter',
                component: PersonalCenter,
                meta: {
                    title: '个人中心'
                }
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
import waterMark from '@/utils/waterMark.js';
router.beforeEach((to, from, next) => {
    Nprogress.start();
    document.title = to.meta.title + ' · 留服搜索机构中心';
    // username.then((res) => {
    // //   waterMark.set(res)
    // })

    next();
});
router.afterEach(() => {
    Nprogress.done();
});
export default router;
