import { paymentType } from '@/utils/public';
export default {
  key: '/order/transactions',
  title: '收支记录',
  type: 'table',
  permission: '/institutions/transactions/list',
  config: {
    dataUrl: '/institutions/transactions/list',
    filter: {
      initUrl: '/institutions/transactions/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'paymentTypeIs',
          label: '类型',
          type: 'select',
          config: {
            options: paymentType
          }
        },
        {
          key: 'time',
          label: '收支时间',
          type: 'dateRange'
        },
        {
          key: 'attributableIdIs',
          label: '订单归属人',
          type: 'select',
          config: {
            options: 'attributablePersonList'
          }
        },
        {
          key: 'followUpIdIs',
          label: '订单跟进人',
          type: 'select',
          config: {
            options: 'followUpPeopleList'
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出收支记录',
        type: 'url',
        permission: '/institutions/transactions/export',
        config: {
          title: '导出收支记录',
          color: 'primary',
        },
        options: {
          url: '/api/institutions/transactions/export',
          params: true,
          newPage: true
        }
      },
    ],
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['time'],
      /* 默认排序 */
      defaultSort: '-time'
    },
    columns: [
      {
        key: 'orderRecord.orderNumber',
        title: '订单号',
        width: 150
      },
      {
        key: 'institutionsCustomerRelationship.name',
        title: '客户姓名',
        width: 150,
        ellipsis: true
      },
      {
        key: 'paymentType',
        title: '类型',
        type: 'enum',
        width: '80px',
        config: {
          constants: {
            PAYMENT: {
              text: '支付',
              color: 'green'
            },
            REFUND: {
              text: '退款',
              color: 'red'
            },
          }
        }
      },
      {
        key: 'time',
        title: '收支时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'amount',
        title: '金额',
        width: 150,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'attributable.name',
        title: '订单归属人',
        width: 150,
      },
      {
        key: 'followUp.name',
        title: '订单跟进人',
        width: 150,
      },
      {
        key: 'operator.name',
        title: '操作人',
        width: 150,
      },
      {
        key: 'createdDate',
        title: '操作时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'actions',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '130px',
      },
    ],

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '订单详情',
        type: 'blank',
        permission: '/institutions/transactions/details',
        config: {
          title: '查看订单详情',
          color: 'primary',
          component: () => import("./views/transactionsDetail")
        }
      },
    ]
  }
};
