// import institutions from "./institutions";
// import institutionsEmployee from "./institutionsEmployee";
// import institutionsStore from "./institutionsStore";
// import diyService from "./diyService";
import serviceItems from "./serviceItems";
import serviceTeam from "./serviceTeam";
import honoraryQualifications from "./honoraryQualifications";
import admissionCases from "./admissionCases";
import userEvaluate from "./userEvaluate";
import institutionsUserEvaluate from "./institutionsUserEvaluate";
import storeManage from "./storeManage";
import strategyInfo from "./strategyInfo";
import reservation from "./reservation";
// import enrollmentProgram from "./enrollmentProgram";
// import honoraryQualificationsSchool from "./honoraryQualificationsSchool";
export default [
  // institutions,
  // institutionsEmployee,
  // institutionsStore,
  // diyService,
  serviceItems,
  serviceTeam,
  honoraryQualifications,
  admissionCases,
  userEvaluate,
  institutionsUserEvaluate,
  storeManage,
  strategyInfo,
  reservation
  // enrollmentProgram,
  // honoraryQualificationsSchool
];
