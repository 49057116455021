export default {
    key: '/system/autoAllocation',
    title: '客资设置',
    permission: '/system/automaticAllocation/list',
    config: {
        component: () => import("./views/autoAllocationDetail"),
        actions: [
            {
                key: 'add',
                text: '修改匹配规则',
                icon: 'add',
                type: 'form',
                permission: '/system/automaticAllocation/edit',
                config: {
                    color: 'primary',
                    title: '修改匹配规则',
                    submitUrl: "/system/automaticAllocation/edit",
                    submitText: '确认',
                    controls: [

                    ]
                }
            },
            {
                text: '客资流失设置',
                permission: '/system/customerCapitalSetting/edit',
            },
        ],
    }
}