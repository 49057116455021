export default {
    key: '/dataReport/dataGlance',
    title: '数据概览',
    permission: '/dataReport/dataOverview/list',
    permissionTit: '核心数据',
    config: {
        component: () => import("./views/dataGlanceDetail"),
        actions: [
            {
                text: '签单转化率',
                permission: '/dataReport/dataOverview/funnelChart',
            },
            {
                text: '商机客资来源',
                permission: '/dataReport/dataOverview/pieChart',
            },
            {
                text: '趋势图',
                permission: '/dataReport/dataOverview/trendChart',
            },
            {
                text: '公司排名',
                isSpecial: true,
                permission: '/dataReport/dataOverview/companyRankings',
            },
            {
                text: '部门排名',
                permission: '/dataReport/dataOverview/sectorRankings',
            },
        ],
    }
} 