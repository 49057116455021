export default {
  key: '/store/serviceTeam',
  title: '服务团队',
  permission: '/clientUser/serviceTeam/getList',
  permissionTit: '服务团队',
  config: {
    component: () => import("./views/serviceTeamDetail"),
    actions: [
      {
        text: '新建服务团队',
        permission: '/clientUser/serviceTeam/insert',
      },
      {
        text: '删除',
        permission: '/clientUser/serviceTeam/delete',
      }
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/clientUser/serviceTeam/update',
      }
    ]
  }
} 