<template>
  <a-card>
    <div class="msg-detail">
      <div v-if="currMessage">
        <h2>{{ currMessage.title }}</h2>
        <p style="color: #666666;padding-top: 20px;">{{ getDate(currMessage.pushTime) }}</p>
        <p style="padding: 20px 0;">摘要：{{ currMessage.summary }}</p>
        <p class="html-img" v-html="currMessage.content"></p>
      </div>
    </div>
  </a-card>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "msgDetail",
  data() {
    return {
      currMessage: null,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {

    async getDetail(id) {
      try {
        let res = await this.$http.get('/message/notification/getMessageDetail', {
          id: id
        })
        if (res.code == '200') {
          this.currMessage = res.data;
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    getDate(date) {
      if (date) {
        return dayjs(date).format('YYYY-MM-DD HH:mm')
      } else {
        return ''
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.msg-detail {
  width: 100%;
  overflow: hidden;
}
</style>
<style>
.html-img img {
  max-width: 100%;
  object-fit: cover;
}
</style>