<template>
  <div v-if="!$isMobile" class="m-welcome">
    <div style="display: flex; align-items: center;">
      <img src="../assets/img/w-logo.png" style="width: 80px; margin: 8px" alt="">
      <span class="in-center">机构中心</span>
    </div>
    <img src="../assets/img/w-banner.svg" style="width: 100%;" alt="">
    <div class="w-button">
      <span @click="toRegister" class="button-primary" style="margin-right: 12px">免费注册</span>
      <span @click="toLogin" class="button-link">登录CRM</span>
    </div>
    <div class="w-believe">
      <p style="font-size: 18px; text-align: center; color:#444444; font-weight: 600; letter-spacing: 1px;">优秀机构的信任</p>
      <div class="w-imgs">
        <div class="hco-items flex-center">
          <div v-for="(item, index) in imgs" class="flex-colum-start">
            <img class="hco-college-logo" v-for="(i, idx) in item.img" :src="i" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="w-ins">
      <p style="font-size: 18px; text-align: center; color:#444444; font-weight: 600; letter-spacing: 1px;">最新入驻机构</p>
      <div class="w-msg">
        <div class="wm-title">
          <span class="wm-text font-14">业务类型</span>
          <span class="wm-text wmw-name font-14">机构名称</span>
          <span class="wm-text wmw-city font-14">城市</span>
          <span class="wm-text font-14">状态</span>
        </div>
        <a-carousel dot-position="right" :autoplay="true" :dots="false">
          <div v-for="(item, index) in inList">
            <div v-if="item[0]" class="flex-center">
              <span class="wm-text">{{ item[0].businessType }}</span>
              <span class="wm-ellipsis wmw-name" style="font-size: 12px;">{{ item[0].institutionsName }}</span>
              <span class="wm-text wmw-city">{{ item[0].city }}</span>
              <span class="wm-text" style="color: #175DFF;font-weight: 500;">入驻成功</span>
            </div>
            <div v-if="item[1]" class="flex-center">
              <span class="wm-text">{{ item[1].businessType }}</span>
              <span class="wm-ellipsis wmw-name" style="font-size: 12px;">{{ item[1].institutionsName }}</span>
              <span class="wm-text wmw-city">{{ item[1].city }}</span>
              <span class="wm-text" style="color: #175DFF; font-weight: 500;">入驻成功</span>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <img src="../assets/img/service.svg" style="width: 100%;" alt="">
    <img src="../assets/img/about.svg" style="width: 100%;" alt="">
    <img src="../assets/img/flow.svg" style="width: 100%; margin-bottom: 20px;" alt="">
    <div class="w-footer">
      <span @click="toRegister" class="button-primary">免费注册</span>
    </div>
    <div class="m-copyright-new">Copyright ©2022-{{ new Date().getFullYear() }} 留服搜索 All Rights Reversed | <a
        href="https://beian.miit.gov.cn">蜀ICP备2022022830号-6</a></div>
  </div>
  <div v-else class="m-welcome">
    <div style="display: flex; align-items: center;">
      <img src="../assets/img/w-logo.png" style="width: 80px; margin: 8px" alt="">
      <span class="in-center">机构中心</span>
    </div>
    <img src="../assets/img/w-banner.svg" style="width: 100%;" alt="">
    <div class="w-button">
      <span @click="toRegister" class="button-primary" style="margin-right: 12px">免费注册</span>
      <span @click="toLogin" class="button-link">登录CRM</span>
    </div>
    <div class="w-believe">
      <p style="font-size: 18px; text-align: center; color:#444444; font-weight: 600; letter-spacing: 1px;">优秀机构的信任</p>
      <div class="w-imgs">
        <div class="hco-items flex-center">
          <div v-for="(item, index) in imgs" class="flex-colum-start">
            <img class="hco-college-logo" v-for="(i, idx) in item.img" :src="i" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="w-ins">
      <p style="font-size: 18px; text-align: center; color:#444444; font-weight: 600; letter-spacing: 1px;">最新入驻机构</p>
      <div class="w-msg">
        <div class="wm-title">
          <span class="wm-text font-14">业务类型</span>
          <span class="wm-text wmw-name font-14">机构名称</span>
          <span class="wm-text wmw-city font-14">城市</span>
          <span class="wm-text font-14">状态</span>
        </div>
        <a-carousel v-if="inList.length > 0" dot-position="right" :autoplay="true" :dots="false">
          <div v-for="(item, index) in inList">
            <div v-if="item[0]" class="flex-center">
              <span class="wm-text">{{ item[0].businessType }}</span>
              <span class="wm-ellipsis wmw-name" style="font-size: 12px;">{{ item[0].institutionsName }}</span>
              <span class="wm-text wmw-city">{{ item[0].city }}</span>
              <span class="wm-text" style="color: #175DFF;font-weight: 500;">入驻成功</span>
            </div>
            <div v-if="item[1]" class="flex-center">
              <span class="wm-text">{{ item[1].businessType }}</span>
              <span class="wm-ellipsis wmw-name" style="font-size: 12px;">{{ item[1].institutionsName }}</span>
              <span class="wm-text wmw-city">{{ item[1].city }}</span>
              <span class="wm-text" style="color: #175DFF; font-weight: 500;">入驻成功</span>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <img src="../assets/img/service.svg" style="width: 100%;" alt="">
    <img src="../assets/img/about.svg" style="width: 100%;" alt="">
    <img src="../assets/img/flow.svg" style="width: 100%; margin-bottom: 20px;" alt="">
    <div class="w-footer">
      <span @click="toRegister" class="button-primary">免费注册</span>
    </div>
    <div class="m-copyright-new">Copyright ©2022-{{ new Date().getFullYear() }} 留服搜索 All Rights Reversed | <a
        href="https://beian.miit.gov.cn">蜀ICP备2022022830号-6</a></div>
  </div>
</template>
<script>
export default {
  name: "message",
  data() {
    return {
      imgs: [
        {
          img: [
            require('../assets/img/in1.png'),
            require('../assets/img/in2.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in3.png'),
            require('../assets/img/in4.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in5.png'),
            require('../assets/img/in6.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in7.png'),
            require('../assets/img/in8.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in9.png'),
            require('../assets/img/in10.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in11.png'),
            require('../assets/img/in12.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in13.png'),
            require('../assets/img/in14.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in15.png'),
            require('../assets/img/in16.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in17.png'),
            require('../assets/img/in18.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in19.png'),
            require('../assets/img/in20.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in21.png'),
            require('../assets/img/in22.png'),
          ]
        },
        {
          img: [
            require('../assets/img/in23.png'),
            require('../assets/img/in24.png'),
          ]
        },
      ],
      inList: []
    }
  },
  mounted() {
    this.clientGetNewSettleInInstitutions();
    if (this.$route.query.ref) {
      localStorage.setItem('ref', this.$route.query.ref)
    }
    if (this.$route.query.refCode) {
      localStorage.setItem('refCode', this.$route.query.refCode)
    }
  },
  methods: {
    //机构
    async clientGetNewSettleInInstitutions() {
      try {
        let res = await this.$http.get('/clientUser/institutions/getNewSettleInInstitutions');
        if (res.code == '200') {
          this.getData(res.data)
        }
      } catch ({ message }) {

      }
    },

    getData(val) {
      let result = [];
      for (let i = 0; i < val.length; i += 2) {
        result.push([val[i], val[i + 1]]);
      }
      this.inList = result;
    },

    toLogin() {
      this.$router.push('/login');
    },

    toRegister() {
      this.$router.push('/register');
    }
  }
}
</script>
<style lang="scss" scoped>
.m-welcome {
  .w-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
  }

  .button-primary {
    display: inline-block;
    width: 116px;
    height: 38px;
    color: #ffffff;
    background-color: #175DFF;
    border: 1px solid #175DFF;
    border-radius: 100px;
    font-size: 14px;
    text-align: center;
    line-height: 38px;
    font-weight: 500;
    cursor: pointer;
  }

  .button-link {
    @extend .button-primary;
    color: #175DFF;
    background-color: #ffffff;
  }

  .w-believe {
    padding: 30px 16px 20px 16px;
  }

  .w-ins {
    padding: 0 16px 30px 16px;
  }

  .w-footer {
    text-align: center;
    // margin-top: -130px;
  }

  .w-msg {
    margin-top: 20px;
    background: linear-gradient(180deg, #DBE5FF 40.21%, #FFFFFF 100%);
    border: 1px solid #F4F4F4;
    border-radius: 6px;
    width: 100%;
  }

  .wm-title {
    color: #ffffff;
    background-color: #175DFF;
    border-radius: 6px 6px 0 0;
    padding: 10px 0;
    margin-bottom: 10px;
  }

  .flex-center {
    display: flex;
    align-items: center;
    margin-bottom: 10PX;
  }

  .wm-text {
    display: inline-block;
    text-align: center;
    width: 25%;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .wm-ellipsis {
    display: inline-block;
    text-align: center;
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .wmw-name {
    width: 30%;
  }

  .wmw-city {
    width: 20%;
  }

  .w-imgs {
    width: 100%;
    overflow: hidden;
  }

  .hco-items {
    padding-top: 20px;
    animation: move 30s linear infinite;
  }

  @keyframes move {
    0% {}

    100% {
      transform: translateX(-95%);
    }
  }

  .hco-college-logo {
    width: 46px;
    height: 45px;
    margin-right: 12px;
    margin-bottom: 10px;
  }

  .in-center {
    color: #666666;
    display: inline-block;
    padding-left: 8px;
    border-left: 1px solid #DCDCDC;
    font-size: 14px;
    font-weight: 600;
  }

  .m-copyright-new {
    text-align: center;
    font-size: 14px;
    font-size: 12px;
    padding: 30px;
    line-height: 20px;

    a {
      text-decoration: none;
    }
  }
}
</style>
