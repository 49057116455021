<template>
  <a-card>
    <div v-if="!$isMobile" class="message">
      <div class="msg-left">
        <div style="display: flex; align-items: center; margin-bottom: 20px;">
          <h2>消息通知</h2>
          <span style="color: #1677ff; cursor: pointer; margin-left: 30px;" @click="readAllMessage">全部已读</span>
        </div>
        <div class="msg-list" v-if="msgList.length > 0">
          <div v-for="(item, index) in msgList" class="ml-item" @click="getDetail(item.id)">
            <div style="display: flex; align-items: center">
              <p class="msg-title">{{ item.title }}</p>
              <span v-if="item.readStatus != 1" class="circle"></span>
            </div>
            <p class="msg-summary">{{ item.summary }}</p>
            <div style="text-align: right; color: #666666">{{ getDate(item.pushTime) }}</div>
          </div>
        </div>
        <div v-else style="text-align: center; height: 800px;padding-right: 30px;border-right: 1px solid #eeeeee;">
          <img style="width: 200px; margin-top: 200px;" src="../../assets/img/no-msg-data.png" alt="">
          <p style="text-align: center;">暂无未读消息哦~</p>
        </div>
        <div
          style="text-align: right; margin-top: 16px; display: flex; align-items: center; justify-content: flex-end;">
          <span style="color: #666666; margin-right: 20px;">总{{ total }}条</span>
          <a-pagination size="small" :total="total" @change="changePage" />
        </div>
      </div>
      <div class="msg-right">
        <div v-if="currMessage">
          <h2>{{ currMessage.title }}</h2>
          <p style="color: #666666;padding-top: 20px;">{{ getDate(currMessage.pushTime) }}</p>
          <p style="padding: 20px 0;">摘要：{{ currMessage.summary }}</p>
          <p class="html-img" v-html="currMessage.content"></p>
        </div>
      </div>
    </div>
    <div v-else class="m-message">
      <div style="display: flex; align-items: center; margin-bottom: 20px;">
        <h2>消息通知</h2>
        <span style="color: #1677ff; cursor: pointer; margin-left: 30px;" @click="readAllMessage">全部已读</span>
      </div>
      <div class="msg-list" v-if="msgList.length > 0">
        <div v-for="(item, index) in msgList" class="ml-item" @click="toMessageId(item.id)">
          <div style="display: flex; align-items: center">
            <p class="msg-title">{{ item.title }}</p>
            <span v-if="item.readStatus != 1" class="circle"></span>
          </div>
          <p class="msg-summary">{{ item.summary }}</p>
          <div style="text-align: right; color: #666666">{{ getDate(item.pushTime) }}</div>
        </div>
      </div>
      <div v-else style="text-align: center; margin: 40px 0 80px 0;">
        <img style="width: 200px;" src="../../assets/img/no-msg-data.png" alt="">
        <p style="text-align: center;">暂无未读消息哦~</p>
      </div>
      <div style="text-align: right; margin-top: 16px; display: flex; align-items: center; justify-content: flex-end;">
        <span style="color: #666666; margin-right: 20px;">总{{ total }}条</span>
        <a-pagination size="small" :total="total" @change="changePage" />
      </div>
    </div>
  </a-card>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "message",
  data() {
    return {
      msgList: [],
      params: {
        page: 1,
        size: 10,
      },
      total: 0,
      currMessage: null,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    }
    this.notificationList();
  },
  methods: {
    async notificationList() {
      try {
        let res = await this.$http.get('/message/notification/list', this.params)
        if (res.code == '200') {
          this.msgList = res.data;
          this.total = res.rows;
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async readAllMessage() {
      try {
        let res = await this.$http.get('/message/notification/readAllMessage')
        if (res.code == '200') {
          this.params.page = 1;
          this.notificationList();
          this.$message.success('操作成功');
          this.$emit('msgChange', true)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getDetail(id) {
      try {
        let res = await this.$http.get('/message/notification/getMessageDetail', {
          id: id
        })
        if (res.code == '200') {
          this.currMessage = res.data;
          this.msgList.map((item, index) => {
            if (item.id == id)
              item.readStatus = 1;
          });
          this.$emit('msgChange', true)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    getDate(date) {
      if (date) {
        return dayjs(date).format('YYYY-MM-DD HH:mm')
      } else {
        return ''
      }
    },

    changePage(e) {
      this.params.page = e;
      this.notificationList();
    },

    toMessageId(id) {
      this.$router.push({
        path: '/msgdetail',
        query: {
          id: id
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.message {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;

  .msg-left {
    width: 20%;
  }

  .msg-right {
    width: 80%;
  }

  .msg-list {
    padding-right: 30px;
    border-right: 1px solid #eeeeee;
    overflow-y: scroll;
    height: 700px;
  }

  .ml-item {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
  }

  .msg-title {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .circle {
    right: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .msg-summary {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* 超出几行省略 */
    overflow: hidden;
    padding: 6px 0;
    color: #666666;
  }

  .msg-right {
    padding: 0 20px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: none;
    /*可以设置轨道颜色*/
    padding-right: 2px;
  }

  /*滚动条*/
  ::-webkit-scrollbar-thumb {
    background: rgba(142, 142, 142, 0.4);
    border-radius: 6px;
  }
}

.m-message {
  .msg-list {}

  .ml-item {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
  }

  .msg-title {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .circle {
    right: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .msg-summary {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* 超出几行省略 */
    overflow: hidden;
    padding: 6px 0;
    color: #666666;
  }
}
</style>
<style>
.html-img img {
  max-width: 100%;
  object-fit: cover;
}
</style>