import pages from "./pages";

let menus = [
  {
    key: 'dataReport',
    text: '数据统计',
    icon: 'LineChartOutlined',
    defaultOpen: false,
    pages: [
      '/dataReport/dataGlance',
      '/dataReport/dataReport',
      '/dataReport/followData'
    ]
  },
  // {
  //   key: 'missionGoals',
  //   text: '任务目标',
  //   icon: 'ContainerOutlined',
  //   defaultOpen: false,
  //   pages: [
  //     '/missionGoals/companyGoals',
  //     '/missionGoals/divGoals',
  //     '/missionGoals/consultDepartGoals',
  //     '/missionGoals/divStaffGoals',
  //     '/missionGoals/counselStaffGoals',
  //   ]
  // },
  {
    key: 'customer',
    text: '客户管理',
    icon: 'TeamOutlined',
    defaultOpen: false,
    pages: [
      '/customer/allocationCustomer',
      '/bom/bomlist',
      '/bom/followup',
      '/customer/highSeas',
    ]
  },
  // {
  //   key: 'bom',
  //   text: '商机管理',
  //   icon: 'BulbOutlined',
  //   defaultOpen: false,
  //   pages: [
  //     '/bom/bomlist',
  //     '/bom/followup',
  //   ]
  // },
  {
    key: 'order',
    text: '订单管理',
    icon: "DeploymentUnitOutlined",
    defaultOpen: false,
    pages: [
      '/order/order',
      '/order/cancelorder',
      '/order/transactions',
    ]
  },
  {
    key: 'postService',
    text: '后期服务',
    icon: "AuditOutlined",
    defaultOpen: false,
    pages: [
      '/postService/postService',
    ]
  },
  {
    key: 'approvalCenter',
    text: '审批中心',
    icon: "AccountBookOutlined",
    defaultOpen: false,
    pages: [
      '/approvalCenter/paymentRefundReview',
    ]
  },
  {
    key: 'fee',
    text: '业绩提成',
    icon: "TransactionOutlined",
    defaultOpen: false,
    pages: [
      '/fee/settlement',
      // '/fee/rules',
    ]
  },
  {
    key: 'store',
    text: '门店管理',
    icon: "AppstoreAddOutlined",
    defaultOpen: false,
    pages: [
      '/store/storeManage',
    ]
  },
  {
    key: 'system',
    text: '系统设置',
    icon: 'SettingOutlined',
    defaultOpen: false,
    pages: [
      '/system/employee',
      '/system/department',
      '/system/role',
      '/system/tags',
      '/system/autoAllocation',
      '/system/operationLog',
    ]
  },
];

export default {
  pages,
  menus
}