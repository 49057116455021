export default function tree(list, options) {
  options = Object.assign({
    parent: 'parentId',
    children: 'children',
    transform(node) {
      return node;
    }
  }, options);

  const nodeMap = list.reduce((prev, node) => {
    prev[node.id] = options.transform(node);
    return prev;
  }, {});

  return list.reduce((prev, node) => {
    const parentId = node[options.parent];
    if (parentId != null) {
      const parentNode = nodeMap[parentId];
      if (parentNode[options.children] == null) {
        parentNode[options.children] = [];
      }
      parentNode[options.children].push(nodeMap[node.id]);
    } else {
      prev.push(nodeMap[node.id]);
    }
    return prev;
  }, []);
}

export function findNode(nodes, key) {
  for (const node of nodes) {
    if (node.key === key) {
      return node;
    }
    if (node.children != null) {
      const childNode = findNode(node.children, key);
      if (childNode != null) {
        return childNode;
      }
    }
  }
  return null;
}

export function disableNode(nodes, key) {
  const node = findNode(nodes, key);
  if (node == null) {
    return;
  }

  function disable(nodes) {
    if (nodes == null) {
      return;
    }

    nodes.forEach(node => {
      node.disabled = true;
      disable(node.children);
    });
  }

  node.disabled = true;
  disable(node.children);
}
