import { intentionLevel, customerStatus, gender, serviceItems, hignEducation, languageType, country, sourceChannel, highSeasReasons } from '@/utils/public';
export default {
  title: '客资公海',
  type: 'table',
  key: '/customer/highSeas',
  permission: '/institutions/highSeas/list',
  config: {
    dataUrl: '/institutions/highSeas/list',
    filter: {
      // initUrl: '/institutions/customer/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'phoneNumberContains',
          label: '手机号',
          type: 'text',
          config: {
            rules: 'phoneNumber'
          }
        },
        {
          key: 'wechatContains',
          label: '微信号',
          type: 'text',
        },
        {
          key: 'moveInReasonIs',
          label: '移入原因',
          type: 'select',
          config: {
            options: highSeasReasons
          }
        },
        {
          key: 'sourceChannelIn',
          label: '来源渠道',
          type: 'mSelect',
          config: {
            options: sourceChannel
          }
        }
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['putInTime'],
      /* 默认排序 */
      defaultSort: '-putInTime'
    },
    checkbox: true,
    actions: [
      {
        key: 'edit',
        text: '领取客资',
        icon: 'edit',
        type: 'form',
        isSelected: true,
        permission: '/institutions/highSeas/receiveCustomerResources',
        config: {
          color: 'primary',
          title: '领取客资',
          info: (rows) => {
            return `您共选择 ${rows.length} 条客资，是否领取？`
          },
          // initUrl: '/institutions/customer/setAttributablePerson/get',
          submitUrl: '/institutions/highSeas/receiveCustomerResources',
          submitText: '确定',
          controls: []
        }
      },
      {
        key: 'edit',
        text: '分配客资',
        icon: 'edit',
        type: 'form',
        isSelected: true,
        permission: '/institutions/highSeas/allocationOfCustomerCapital',
        config: {
          color: 'primary',
          title: '分配客资',
          info: (rows) => {
            return `您共选择 ${rows.length} 条客资，请选择归属人`
          },
          initUrl: '/institutions/highSeas/allocationOfCustomerCapital/get',
          submitUrl: '/institutions/highSeas/allocationOfCustomerCapital',
          submitText: '确定',
          controls: [
            {
              key: 'attributablePersonId',
              label: '归属人',
              type: 'select',
              required: true,
              config: {
                options: 'attributablePersonList'
              }
            },
            {}
          ]
        }
      }
    ],
    columns: [
      {
        key: 'name',
        title: '客户姓名',
        width: 150,
      },
      // {
      //   key: 'userLabelList',
      //   title: '客户标签',
      //   width: 280,
      //   type: 'color',
      //   config: {
      //     textkey: 'content',
      //     colorkey: 'color'
      //   }
      // },
      {
        key: 'phoneNumber',
        title: '手机号',
        width: 150,
      },
      {
        key: 'wechat',
        title: '微信号',
        width: 150,
      },
      {
        key: 'putInTime',
        title: '移入公海时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'moveInReason',
        title: '移入原因',
        width: 150,
      },
      {
        key: 'sourceChannel',
        title: '来源渠道',
        width: 150,
      },
      {
        key: 'attribution.name',
        title: '最后归属人',
        width: 150,
      },

      {
        key: 'followUpWithPeople.name',
        title: '最后跟进人',
        width: 150,
      },
      {
        key: 'institutionsFollowUp.followUpTime',
        title: '最后跟进时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'institutionsFollowUp.followUpContent',
        title: '最后跟进情况',
        ellipsis: true,
        width: 150,
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: '80px'
      }
    ],
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/institutions/highSeas/details',
        config: {
          title: '客户详情',
          component: () => import("./views/highSeasDetail")
        }
      },
    ]
  }
}
