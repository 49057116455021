<template>
  <div v-if="!$isMobile" class="login">
    <div>
      <img src="../assets/img/pc-login-logo.png" style="margin: 40px; width: 200px;" alt="">
    </div>
    <div class="login-body">
      <img src="../assets/img/pc-login-banner.png" style="width: 400px; margin-right: 200px;" alt="">
      <a-card class="lb-card">
        <h2 style="text-align: center; margin-bottom: 70px; margin-top: 20px;">登录CRM系统</h2>
        <a-form layout="vertical" :model="form" name="basic" autocomplete="off" @finish="onFinish"
          @finishFailed="onFinishFailed">
          <a-form-item style="margin-bottom: 40px;" label="" name="phoneNumber" :rules="[{ required: true, message: '输入用户名或手机号' }]">
            <a-input size="large" v-model:value.trim="form.phoneNumber" placeholder="输入用户名或手机号">
            </a-input>
          </a-form-item>
          <a-form-item label="" name="password" :rules="[{ required: true, message: '输入密码' }]">
            <a-input-password size="large" v-model:value.trim="form.password" placeholder="输入密码">
            </a-input-password>
          </a-form-item>
          <div style="text-align: right; margin-bottom: 60px;">
            <span @click="toRegister" style="margin-right: 20px; color: #175DFF; cursor: pointer;">注册</span>
            <span @click="toReset" style="color: #175DFF; cursor: pointer;">忘记密码？</span>
          </div>
          <a-form-item>
            <div class="btnCon">
              <a-button class="start" block size="large" type="primary" html-type="submit">登录</a-button>
            </div>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
    <div class="m-copyright-new">Copyright ©2022-{{ new Date().getFullYear() }} 留服搜索 All Rights Reversed | <a
        href="https://beian.miit.gov.cn">蜀ICP备2022022830号-6</a></div>
    <a-modal width="400px" v-model:open="openInstitution" title="欢迎登录留服搜索CRM系统">
      <p style="margin: 20px 0 10px 0;">请选择登录的机构</p>
      <div style="overflow-y: scroll; max-height: 200px;padding-top: 10px;">
        <div v-for="(item, index) in inList.value" @click="realLogin(item)" class="m-il-item-new">
          <span style="font-size:14px; ">{{ item.name }}</span>
          <RightOutlined />
        </div>
      </div>
      <template #footer>
      </template>
    </a-modal>
  </div>
  <div class="m-login-new" v-else>
    <div class="ml-header">
      <img src="../assets/img/logo-op.png" style="width: 75px;padding-left: 20px;" alt="">
      <p style="font-size: 22px; font-weight: 600; margin-top: 60px; padding-left: 20px;">登录CRM管理系统</p>
    </div>
    <div style="padding: 30px 20px;">
      <a-form layout="vertical" :model="form" name="basic" autocomplete="off" @finish="onFinish"
        @finishFailed="onFinishFailed">
        <a-form-item label="" name="phoneNumber" :rules="[{ required: true, message: '输入用户名或手机号' }]">
          <a-input size="large" v-model:value.trim="form.phoneNumber" placeholder="输入用户名或手机号">
          </a-input>
        </a-form-item>
        <a-form-item label="" name="password" :rules="[{ required: true, message: '输入密码' }]">
          <a-input-password size="large" v-model:value.trim="form.password" placeholder="输入密码">
          </a-input-password>
        </a-form-item>
        <div style="text-align: right; margin-bottom: 40px;">
          <span @click="toRegister" style="margin-right: 20px; color: #175DFF;">注册</span>
          <span @click="toReset" style="color: #175DFF;">忘记密码？</span>
        </div>
        <a-form-item>
          <div class="btnCon">
            <a-button class="start" block size="large" type="primary" html-type="submit">登录</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div class="m-copyright-new">Copyright ©2022-{{ new Date().getFullYear() }} 留服搜索 All Rights Reversed | <a
        href="https://beian.miit.gov.cn">蜀ICP备2022022830号-6</a></div>
    <a-modal width="300px" v-model:open="openInstitution" title="欢迎登录留服搜索CRM系统">
      <p style="margin: 20px 0 10px 0;">请选择登录的机构</p>
      <div style="overflow-y: scroll; max-height: 200px;padding-top: 10px;">
        <div v-for="(item, index) in inList.value" @click="realLogin(item)" class="m-il-item-new">
          <span style="font-size:14px; ">{{ item.name }}</span>
          <RightOutlined />
        </div>
      </div>
      <template #footer>
      </template>
    </a-modal>
  </div>
</template>
<script setup>
import { onMounted, getCurrentInstance, reactive, ref } from 'vue';

// Vue3官网中强烈建议不要把它当作在组合式 API 中获取 this 的替代方案来使用。
let internalInstance = getCurrentInstance();
let $http = internalInstance.appContext.config.globalProperties.$http;
let $message = internalInstance.appContext.config.globalProperties.$message;
let $router = internalInstance.appContext.config.globalProperties.$router;
onMounted(() => {
  //console.log(internalInstance.appContext.config.globalProperties);		// globalName
})
const open = ref(false);
const openInstitution = ref(false)
const showModal = () => {
  open.value = true;
};
const inList = reactive([])
const handleOk = e => {
  //console.log(e);
  open.value = false;
};
const form = reactive({
  username: '',
  password: '',
});

const toRegister = () => {
  $router.push('/register');
}
const toReset = () => {
  $router.push('/reset');
}
const onFinish = async values => {
  try {
    let res = await $http.post('/auth/preLogin', {
      ...form, url: window.location.origin
    });
    if (res.code == '200') {
      if (res.data.status == 0) {
        $router.push({
          path: '/register',
          query: {
            status: 2,
            phone: res.data.phoneNumber,
            id: res.data.id
          }
        });
      } else if (res.data.status == 1 || res.data.status == 2) {
        $router.push({
          path: '/register',
          query: {
            status: 3,
            phone: res.data.phoneNumber,
          }
        });
      } else if (res.data.status == 3) {
        if (res.data.orgList.length == 0) {
          $message.error('暂无可登录的机构');
        } else {
          if (res.data.orgList.length > 1) {
            openInstitution.value = true;
            inList.value = res.data.orgList;
          } else if (res.data.orgList.length == 1) {
            realLogin(res.data.orgList[0])
          }
        }
      }
    } else {
      $message.error('账号或密码不正确');
    }
  } catch ({ message }) {
    $message.error(message);
  }
};

const realLogin = async val => {
  try {
    let res = await $http.post('/auth/loginPlus', {
      employeeId: val.employeeId,
      institutionsId: val.id
    });
    if (res.code == '200') {
      $message.success('登录成功');
      await $router.push('/home');
    }
  } catch ({ message }) {
    $message.error(message);
  }
}

const onFinishFailed = errorInfo => {
  //console.log('Failed:', errorInfo);
};

</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: url('../assets/img/pc-login-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .login-body {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .lb-card {
    width: 400px;
    height: 500px;
  }

  .ml-header {
    background: url('../assets/img/m-login-bg.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 180px;
    padding: 16px 0;
  }

  .ant-input-affix-wrapper {
    border-color: #DCDCDC;
  }

  .ant-input-lg,
  .ant-input-affix-wrapper-lg {
    border-radius: 40px;
    padding: 8px 20px;
  }

  .ant-btn.ant-btn-lg {
    border-radius: 40px;
  }

  .m-copyright-new {
    position: fixed;
    bottom: 40px;
    right: 0;
    left: 60px;
    font-size: 14px;
    padding: 0 30px;
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    a {
      text-decoration: none;
      color: #999999;
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}

.m-il-item-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DCDCDC;
  background-color: #FBFBFB;
  border-radius: 8px;
  width: calc(100% - 42px);
  padding: 10px 20px;
  cursor: pointer;
  color: #333333;
  margin-bottom: 10px;
}
</style>
<style lang="scss" scoped>
.m-login-new {
  height: 100vh;

  .ml-header {
    background: url('../assets/img/m-login-bg.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 180px;
    padding: 16px 0;
  }


  .ant-input-affix-wrapper {
    border-color: #DCDCDC;
  }

  .ant-input-lg,
  .ant-input-affix-wrapper-lg {
    border-radius: 40px;
    padding: 8px 20px;
  }

  .ant-btn.ant-btn-lg {
    border-radius: 40px;
  }

  .m-copyright-new {
    text-align: center;
    position: fixed;
    bottom: 20px;
    right: 0;
    left: 0;
    font-size: 14px;
    padding: 0 30px;
    font-size: 12px;
    line-height: 20px;

    a {
      text-decoration: none;
    }
  }
}

.m-il-item-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DCDCDC;
  background-color: #FBFBFB;
  border-radius: 8px;
  width: calc(100% - 42px);
  padding: 10px 20px;
  cursor: pointer;
  color: #333333;
  margin-bottom: 10px;
}
</style>
