export default {
  key: '/system/operationLog',
  title: '操作日志',
  permission: '/login/log/list',
  config: {
    component: () => import("./views/operationLog"),
    // actions: [
    //   {
    //     text: '登录日志列表',
    //     permission: '/login/log/list',
    //   }
    // ],
  }
}