import { reasonCancel, orderCancel } from '@/utils/public';
export default {
  key: '/order/cancelorder',
  title: '取消的订单',
  type: 'table',
  permission: '/institutions/institutionsCancelledOrders/list',
  config: {
    dataUrl: '/institutions/institutionsCancelledOrders/list',
    filter: {
      initUrl: "/institutions/institutionsCancelledOrders/list/get",
      controls: [
        {
          key: 'cancelTime',
          label: '取消时间',
          type: 'dateRange'
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'reasonForCancellationIs',
          label: '退款原因',
          type: 'select',
          config: {
            options: reasonCancel
          }
        },
        {
          key: 'paymentStatusIs',
          label: '支付状态',
          type: 'select',
          config: {
            options: orderCancel
          }
        },
        {
          key: 'followUpIdIs',
          label: '订单跟进人',
          type: 'select',
          config: {
            options: 'followUpPeopleList'
          }
        },
        {
          key: 'attributableIdIs',
          label: '订单归属人',
          type: 'select',
          config: {
            options: 'attributablePersonList'
          }
        }
      ]
    },

    /* 表格操作 */
    actions: [
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['cancelTime'],
      /* 默认排序 */
      defaultSort: '-cancelTime'
    },
    columns: [
      {
        key: 'orderNumber',
        title: '订单号',
        width: '150px'
      },
      {
        key: 'signingTime',
        title: '签单时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'cancelTime',
        title: '取消时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'institutionsCustomerRelationship.name',
        title: '客户姓名',
        width: 150,
      },
      {
        key: 'serviceItem.content',
        title: '服务项目',
        width: 150,
      },
      {
        key: 'orderAmount',
        title: '订单金额',
        type: 'numeric',
        width: 150,
        config: {
          format: 'currency'
        }
      },
      {
        key: 'amountPaid',
        title: '已付金额',
        type: 'numeric',
        width: 150,
        config: {
          format: 'currency'
        }
      },
      {
        key: 'amountToBeRefunded',
        title: '应退金额(元)',
        type: 'numeric',
        width: 150,
        config: {
          format: 'currency'
        }
      },
      {
        key: 'refundedAmount',
        title: '已退金额(元)',
        type: 'numeric',
        width: 150,
        config: {
          format: 'currency'
        }
      },
      {
        key: 'paymentStatus',
        title: '支付状态',
        type: 'enum',
        width: 150,
        config: {
          constants: {
            TO_BE_PAID: {
              text: '待支付',
              color: 'orange'
            },
            IN_PAYMENT: {
              text: '支付中',
              color: 'green'
            },
            PAYMENT_COMPLETED: {
              text: '支付完成',
              color: 'cyan'
            },
            REFUND_IN_PROCESS: {
              text: '退款中',
              color: 'red'
            },
            REFUND_COMPLETE: {
              text: '退款完成',
              color: 'gray'
            },
          }
        }
      },
      {
        key: 'reasonForCancellation',
        title: '取消原因',
        width: 150,
        ellipsis: true
      },
      {
        key: 'followUp.name',
        title: '订单跟进人',
        width: 150,
      },
      {
        key: 'attributable.name',
        title: '订单归属人',
        width: 150,
      },
      {
        key: 'orderStatus',
        title: '订单情况',
        width: 150,
        ellipsis: true
      },
      {
        key: 'actions',
        title: '操作',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '280px',
        fixed: 'right'
      },
    ],

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '订单详情',
        type: 'blank',
        permission: '/institutions/institutionsCancelledOrders/details',
        config: {
          title: '订单详情',
          component: () => import("./views/cancelOrderDetail")
        }
      },
      {
        key: 'edit',
        text: '编辑',
        icon: 'edit',
        type: 'form',
        permission: '/institutions/institutionsCancelledOrders/edit',
        config: {
          color: 'primary',
          title: '编辑订单情况',
          initUrl: "/institutions/institutionsCancelledOrders/edit/get",
          submitUrl: "/institutions/institutionsCancelledOrders/edit",
          submitText: '确认修改',
          controls: [
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
              config: {
                // rules: [
                //   { min: 1, max: 30, message: '只能输入1-30个字符' }
                // ]
              }
            },
            {}
          ]
        }
      },
      {
        key: 'add',
        text: '退款',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/institutionsCancelledOrders/refund',
        disabled: ({ selectedRows }) => {
          return selectedRows[0].paymentStatus === "REFUND_COMPLETE";
        },
        config: {
          color: 'primary',
          title: '退款',
          initUrl: '/institutions/institutionsCancelledOrders/refund/get',
          submitUrl: '/institutions/institutionsCancelledOrders/refund',
          submitText: '确认',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              isDisabled: (rows, form)=>{
                return true
              },
              type: 'select',
              config: {
                textKey: 'content',
                options: "signingItemList",
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amountPaid',

              label: '已付金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amountToBeRefunded',
              label: '应退全额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'refundedAmount',
              label: '已退金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amount',
              label: '本次退款',
              type: 'numeric',
              required: true,
              config: {
                format: 'currency',
                max: (form) => {
                  return form.amountToBeRefunded - form.refundedAmount
                }
              }
            },
            {
              key: 'time',
              label: '退款日期',
              type: 'date',
              required: true,
              showNow: true,
              disabledDate: true,
            }
          ]
        }
      },
    ]
  }
};
