<template>
  <div class="inline">
    <span @click="openDrawer" v-if="disabledValue !== 'false'">
      <a-button v-if="type === 'button'" :type="action.config.color" :disabled="!disabledValue">
        <template #icon>
          <component v-if="action.icon" :is="$icons[action.icon]"></component>
        </template>
        {{ action.text }}</a-button>
      <a-button v-else-if="type === 'link'" :class="{ 'text-danger': action.config.color === 'danger' }"
        :disabled="!disabledValue" type="link">
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ action.text }}
      </a-button>
    </span>


    <a-drawer :title="action.config.title" placement="right" :closable="false" :destroy-on-close="true" :width="$isMobile ? '80%' : '50%'"
      :visible="drawerVisible" @close="closeDrawer">
      <component v-if="components != null" :is="components" :selected-rows="selectedRows" @close="closeAndFetch" />
    </a-drawer>
  </div>
</template>

<script>
import { getProfile } from "@/utils/session";
import { shallowRef } from "vue";
export default {
  name: "BlankAction",
  props: ['action', 'type', 'selectedRows', 'disabledValue'],
  data() {
    return {
      drawerVisible: false,
      components: null
    }
  },
  computed: {
    config() {
      return this.action.config;
    },

    // disabled() {
    //   if (this.action.disabled == null) {
    //     return false;
    //   }

    //   if (typeof this.action.disabled === 'function') {
    //     return this.action.disabled({
    //       selectedRows: this.selectedRows
    //     });
    //   }
    //   return this.action.disabled;
    // },

    // props() {
    //   if (this.type === 'button') {
    //     return {
    //       is: 'a-button',
    //       disabled: this.disabled,
    //       type: this.action.config.color,
    //       icon: this.action.icon
    //     };
    //   }
    //   return {
    //     is: 'a',
    //     class: { 'text-danger': this.action.config.color === 'danger' },
    //     disabled: this.disabled
    //   };
    // }
  },

  methods: {

    async openDrawer() {
      getProfile()
        .then(async (result) => {

          if (!result.everyPermissions(this.action.permission)) {
            this.$message.error("没有权限访问");
            return;
          }
          this.drawerVisible = true;
          if (this.components == null) {
            this.components = shallowRef((await this.config.component()).default);
          }
        })
    },


    closeDrawer() {
      this.drawerVisible = false;
    },
    closeAndFetch() {
      this.$emit('change');
      this.drawerVisible = false;
    }

  }
}
</script>
