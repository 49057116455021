<template>
  <div class="inline">
    <span @click="openDrawer" v-if="disabledValue !== 'false'">
      <a-button v-if="type === 'button'" :type="action.config.color" :disabled="!disabledValue">
        <template #icon>
          <component v-if="action.icon" :is="$icons[action.icon]"></component>
        </template>
        {{ actionText }}</a-button>
      <a-button v-else-if="type === 'link'" style="padding: 0;"
        :class="{ 'text-danger': action.config.color === 'danger' }" :disabled="!disabledValue" type="link">
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ action.text }}
      </a-button>
    </span>
    <a-modal width="400px" v-model:open="drawerVisible" :title="action.config.title" @cancel="closeDrawer" @ok="closeDrawer">
      <a-skeleton v-if="initLoading" active :title="false" :paragraph="{ rows: 4 }" />
      <a-form v-else-if="initDataReady" ref="form" :model="form" :rules="rules" class="formCon">
        <div v-html="modalContent" style="margin-bottom: 15px;text-align: center;"></div>
        <a-form-item v-for="control in controls" :key="control.key" :label="control.label" :name="control.key">
          <!-- 文本 -->
          <template v-if="control.type === 'text'">
            <a-input v-model:value.trim="form[control.key]" :placeholder="getPlaceholder(control)"
              :disabled="control.disabled" allow-clear />
          </template>

          <!-- 日期 -->
          <template v-else-if="control.type === 'date'">
            <a-date-picker v-model:value="form[control.key]" :placeholder="getPlaceholder(control)" style="width: 100%"
              :getPopupContainer="triggerNode => {
                return triggerNode.parentNode || document.body;
              }" />
          </template>

          <!-- 日期时间 -->
          <template v-else-if="control.type === 'datetime'">
            <a-date-picker show-time v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
              :getPopupContainer="triggerNode => {
                return triggerNode.parentNode || document.body;
              }" style="width: 100%" />
          </template>

          <!-- 文本域 -->
          <template v-else-if="control.type === 'textarea'">
            <a-textarea v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
              style="width: 100%;height: 100px;" />
          </template>

          <!-- 单选框 -->
          <template v-else-if="control.type === 'radio'">
            <a-radio-group v-model:value="form[control.key]">
              <a-radio v-for="option in control.config.options" :key="option.value" :value="option.value">
                {{ option.text }}
              </a-radio>
            </a-radio-group>
          </template>

          <!-- 数值 -->
          <template v-else-if="control.type === 'numeric'">

            <!-- 金额 -->
            <template v-if="control.config.format === 'currency'">
              <a-input-number
                :formatter="value => (value == null || value === '') ? value : `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/¥\s?|(,*)/g, '')" v-model:value="form[control.key]"
                :placeholder="getPlaceholder(control)" style="width: 100%" />
            </template>

            <!-- 百分比 -->
            <template v-else-if="control.config.format === 'percent'">
              <a-input-number :min="0" :max="100"
                :formatter="value => (value == null || value === '') ? value : `${value}%`"
                :parser="value => value.replace('%', '')" v-model:value="form[control.key]"
                :placeholder="getPlaceholder(control)" style="width: 100%" />
            </template>
            <!-- 普通 单位万 -->
            <template v-else-if="control.config.format === 'money'">
              <a-input prefix="￥" suffix="万" v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
                style="width: 100%" oninput="value=value.replace(/(\.\d{2})\d*$/, '$1')"
                onblur="value = value.replace(/(\.\d{2})\d*$/, '$1')"
                :formatter="value => (value == null || value === '') ? value : `¥ ${value}`.replace(/(\.\d{2})\d*$/, '$1')"
                :parser="value => value.replace(/(\.\d{2})\d*$/, '$1')" />
            </template>
            <!-- 普通 -->
            <template v-else>
              <a-input-number v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
                style="width: 100%" />
            </template>
          </template>

          <!-- 下拉选择 -->
          <template v-else-if="control.type === 'select'">
            <a-select :disabled="control.disabled" :style="{ width: control.config.width + 'px' }"
              v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
              @change="value => cascadeSelect(value, control.isCascade, control.cascadeData)" allow-clear
              style="width: 100%">
              <a-select-option v-for="option in control.config.options" :key="option.value">{{ option.text }}
              </a-select-option>
            </a-select>
          </template>
          <!-- 下拉多选选择 -->
          <template v-else-if="control.type === 'mSelect'">
            <a-select :style="{ width: control.config.width + 'px' }" v-model:value="form[control.key]"
              :placeholder="getPlaceholder(control)" mode="multiple" allow-clear style="width: 100%" show-search
              :filter-option="filterOption" @change="getSelect($event, control)">
              <a-select-option v-for="option in control.config.options" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </template>
          <!-- 下拉搜索选择 -->
          <template v-else-if="control.type === 'souSelect'">
            <a-select class="page-btm" :filter-option="filterSouOption" show-search v-model:value="form[control.key]"
              placeholder="全部" allow-clear>
              <a-select-option v-for="option in control.config.options" :key="option.value" :text="option.text">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </template>
          <!-- switch -->
          <template v-else-if="control.type === 'switch'">
            <a-switch v-model:checked="form[control.key]" default-checked />
          </template>

          <!-- 文件 -->
          <template v-else-if="control.type === 'file'">
            <a-upload :accept="control.config.accept.map(ext => `.${ext}`).join(',')" :action="control.config.uploadUrl"
              :list-type="control.config.format === 'image' ? 'picture-card' : 'text'"
              :file-list="fileUploadMap[control.key].fileList" :before-upload="file => onBeforeUpload(control, file)"
              @preview="handleUploadPreview($event, control)" @change="info => handleUploadChange(control, info)">
              <template v-if="fileUploadMap[control.key].fileList.length < 1">
                <div v-if="control.config.format === 'image'">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
                <div v-else-if="control.config.format === 'compress'">
                  <a-button>
                    <upload-outlined />
                    选择压缩文件
                  </a-button>
                </div>
                <div v-else-if="control.config.format === 'video'">
                  <a-button>
                    <upload-outlined />
                    选择视频文件
                  </a-button>
                </div>
              </template>
            </a-upload>
            <a-modal title="文件预览" :visible="fileUploadMap[control.key].previewVisible"
              @cancel="fileUploadMap[control.key].previewVisible = false" :footer="null">
              <template v-if="control.config.format === 'image'">
                <img style="width: 100%" :src="fileUploadMap[control.key].previewUrl" alt="预览图" />
              </template>
              <template v-if="control.config.format === 'video'">
                <video :src="fileUploadMap[control.key].previewUrl" controls style="width: 100%" />
              </template>
            </a-modal>
            <span v-if="control.config.imageSize">推荐上传尺寸:{{ control.config.imageSize }}</span>
          </template>

          <!-- 树状下拉选择 -->
          <template v-else-if="control.type === 'treeSelect'">
            <a-tree-select v-model:value="form[control.key]" style="width: 100%" allow-clear
              :tree-data="getTreeData(control)" tree-default-expand-all :placeholder="getPlaceholder(control)">
            </a-tree-select>
          </template>

          <!-- 日期范围 -->
          <template v-else-if="control.type === 'dateRange'">
            <a-range-picker v-model:value="form[control.key]" style="width: 100%" :show-time="control.config.showTime"
              :placeholder="[getPlaceholder(control), getPlaceholder(control)]" />
          </template>

          <!-- 复选框 -->
          <template v-else-if="control.type === 'checkbox'">
            <a-checkbox-group v-model:value="form[control.key]">
              <template v-for="option in control.config.options">
                <template v-if="!option.disabled || form[control.key].find(v => v === option.value)">
                  <a-checkbox :value="option.value">{{ option.text }}</a-checkbox>
                  <br v-if="control.config.layout === 'vertical'" />
                </template>
              </template>
            </a-checkbox-group>
          </template>

          <!-- 树状 -->
          <template v-else-if="control.type === 'tree'">
            <a-tree :selectable="false" v-model:checkedKeys="form[control.key]" checkable blockNode
              :tree-data="control.config.options">
              <!-- <template #icon>
                <component v-if="icon" :is="$icons[icon]"></component>
              </template> -->
            </a-tree>
          </template>

          <!-- 级联选择 -->
          <template v-else-if="control.type === 'cascader'">
            <a-cascader v-model:value="form[control.key]" :show-search="{ filter }" change-on-select
              :options="control.config.options" placeholder="全部" />
          </template>
          <!-- 步骤条 -->
          <template v-else-if="control.type === 'steps'">
            <a-steps v-model:current="currentStep" type="navigation" size="small" :items="control.config.options"
              @change="getStep(control.key, control.config.options, currentStep)"></a-steps>
          </template>

          <!-- 穿梭框 -->
          <template v-else-if="control.type === 'transfer'">
            <a-transfer v-model:target-keys="form[control.key]" :data-source="control.config.options" :one-way="true"
              :titles="[control.config.source, control.config.target]" :render="item => item.title" />
          </template>
        </a-form-item>
      </a-form>
      <template #footer>
        <div style="text-align: center;">
          <a-button @click="closeDrawer">取消</a-button>
          <a-button v-if="action.key == 'download'" type="primary" @click="handleUrlAction(action.options, selectedRows)"
            :loading="submitLoading">{{ action.config.submitText }}</a-button>
          <a-button v-else type="primary" @click="submit" :loading="submitLoading">{{ action.config.submitText
          }}</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal v-model:open="openModal" :title="modalTitle">
      <div v-html="modalContent"></div>
      <template #footer>
        <div style="text-align: center;">
          <a-button @click="closeModal">知道了</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { capitalize, formatBytes } from "@/utils/core";
import { toRaw } from '@vue/reactivity';
import { get } from "@/utils/http";
import tree from "@/utils/tree";
import config from "@/config";
import { getProfile } from "@/utils/session";
import dayjs from "dayjs";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "DialogAction",
  props: ['action', 'type', 'selectedRows', 'disabledValue', 'tableData', 'filterForm', 'actionText'],
  data() {
    return {
      drawerVisible: false,
      submitLoading: false,
      form: this.initForm(),
      initData: {},
      initLoading: false,
      initDataReady: false,
      fileUploadMap: this.initFileUploadMap(),
      current: 0,
      currentStep: 0,
      openModal: false,
      modalTitle: '',
      modalContent: '',
      transferData: []
    }
  },
  computed: {

    /**
     * 验证规则
     */
    rules() {
      return this.controls.reduce((prev, control) => {
        const controlRules = prev[control.key] = prev[control.key] ?? [];
        if (control.required) {
          controlRules.push({
            required: true,
            message: `请${this.getPlaceholder(control)}`
          });
        }
        if (control.config.rules != null) {
          const configRules = typeof control.config.rules === 'string' ? [control.config.rules] : control.config.rules;
          configRules.forEach(ruleName => {
            if (ruleName === 'phoneNumber') {
              controlRules.push({
                pattern: /^1\d{10}$/,
                message: '请输入正确的手机号'
              });
            } else if (ruleName === 'email') {
              controlRules.push({
                type: 'email',
                message: '请输入正确的邮箱地址'
              });
            } else if (ruleName === 'wechat') {
              controlRules.push({
                pattern: /^[a-zA-Z-_0-9]([-_a-zA-Z0-9]{5,19})+$/,
                message: '请输入正确的微信号'
              });
            } else if (ruleName === 'url') {
              controlRules.push({
                pattern: /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/,
                message: '请输入以 http:// 或 https:// 开头的正确网址'
              });
              controlRules.push({ max: 250, message: '链接地址不能超过500字符' });
            } else {
              controlRules.push(ruleName);
            }
          });
        }
        return prev;
      }, {});
    },

    /**
     * 表单项
     */
    controls() {
      const controls = this.action.config.controls ?? [];
      return controls.map(control => {
        return {
          ...control,
          config: {
            rules: [],
            ...control.config,
            ...(control.type === 'select' || control.type === 'souSelect' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                text: item[control.config.textKey ?? 'name'],
                value: item[control.config.valueKey ?? 'id']
              })) : control.config.options
            } : {}),
            ...(control.type === 'mSelect' ? {
              // options: this.initData[control.config.options],
              options: this.getMSelectData(this.initData, control)
            } : {}),
            ...(control.type === 'steps' ? {
              options: this.getStepsData(this.initData, control)
            } : {}),
            ...(control.type === 'file' ? {
              accept: control.config.accept ?? ({
                image: ['png', 'jpg', 'gif'],
                compress: ['zip', 'rar', '7z']
              }[control.config.format])
            } : {}),
            ...(control.type === 'checkbox' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                text: item[control.config.textKey ?? 'name'],
                value: item[control.config.valueKey ?? 'id'],
                disabled: item['locked']
              })) : control.config.options
            } : {}),
            ...(control.type === 'tree' ? {
              options: typeof control.config.options === 'function' ? control.config.options({ config }) : control.config.options
            } : {}),
            ...(control.type === 'transfer' ? {
              options: [] ? this.transferData : options
            } : {}),
            ...(control.type === 'treeSelect' ? {
              options: typeof control.config.options === 'string'
                ? tree(this.initData[control.config.options] ?? [], {
                  transform(node) {
                    const value = node[control.config.valueKey ?? 'id'];
                    return {
                      title: node[control.config.textKey ?? 'name'],
                      value,
                      key: value
                    };
                  }
                }) : control.config.options
            } : {})
          }
        }
      });
    },

    /**
     * 是否禁用
     */
    disabled() {
      if (this.action.disabled == null) {
        return false;
      }

      if (typeof this.action.disabled === 'function') {
        return this.action.disabled({
          selectedRows: this.selectedRows
        });
      }

      return this.action.disabled;
    },
  },

  mounted() {
    this.action.config.controls.map((item) => {
      if(item.defaultValue) {
        this.form[item.key] = item.defaultValue;
      }
    });
  },

  methods: {
    handleUrlAction(action, rows) {
      const urlStr = action.url.replace(/{(\w+)}/g, (substr, group) => rows[0][group]);
      const url = new URL(/^https?:\/\//.test(urlStr) ? urlStr : window.location.origin + urlStr);
      if (action.params === true) {
        const listForm = this.filterForm;
        Object.keys(listForm).forEach(key => {
          const value = listForm[key];
          if (Array.isArray(value) && toRaw(value[0]).$d) {
            const onOrAfter = dayjs(toRaw(value[0]).$d).format('YYYY-MM-DD') + ' 00:00:00';
            const onOrBefore = dayjs(value[1].$d).format('YYYY-MM-DD') + ' 23:59:59';
            url.searchParams.append(`${key}OnOrAfter`, onOrAfter);
            url.searchParams.append(`${key}OnOrBefore`, onOrBefore);
          } else {
            url.searchParams.append(key, listForm[key]);
          }
          // if (value != null && value.length !== 0) {
          //   url.searchParams.append(key, listForm[key]);
          // }
        });
      }
      if (action.newPage) {
        window.open(url.toString());
      } else {
        window.location.href = url.toString();
      }
      this.closeDrawer();
      this.$emit('change');
    },
    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getSelect(value, control) {
      if (control.limitNum && value.length > control.limitNum) {
        value.pop();
        this.$message.error(`最多只能选择${control.limitNum}个`);
      }
    },
    getStep(key, options, current) {
      this.form[key] = options[current].value;
    },
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) > -1);
    },
    initForm() {
      return this.action.config.controls.reduce((prev, control) => {
        if (control.type === 'dateRange') {
          const start = this.initData?.source?.[control.config.startKey] ?? control.config?.initialValue?.[0];
          const end = this.initData?.source?.[control.config.endKey] ?? control.config?.initialValue?.[1];
          prev[control.config.startKey] = start;
          prev[control.config.endKey] = end;
          prev[control.key] = [start != null ? dayjs(start) : null, end != null ? dayjs(end) : null];
        } else if (control.type === 'steps') {
          prev[control.key] = this.initData?.source?.[control.key] ?? control.config?.options[0].value;
          control.config.options.map((item, index) => {
            if (item.value == prev[control.key]) {
              this.currentStep = index;
              control.config.options = control.config.options.map((i, idx) => {
                if (idx < index) {
                  return {
                    ...i,
                    status: 'finish'
                  };
                }
                return i;
              });
            }
          })
          // this.current = control.config.options.findIndex(item => item.value === prev[control.key]);
          // control.config.options = control.config.options.map((item, index) => {
          //   if (index < that.current) {
          //     return {
          //       ...item,
          //       status: 'finish'
          //     };
          //   }
          //   return item;
          // });
        } else {
          prev[control.key] = this.initData?.source?.[control.key] ?? control.config?.initialValue;
          if (control.type === 'switch') {
            prev[control.key] = prev[control.key] ?? false;
          } else if (control.type === 'date' || control.type === 'datetime') {
            if (typeof prev[control.key] === 'string') {
              prev[control.key] = dayjs(prev[control.key]);
            }
          }
        }
        return prev;
      }, {});
    },

    initFileUploadMap() {
      return this.action.config.controls.filter(({ type }) => type === 'file').reduce((prev, control) => {
        const path = this.initData?.source?.[control.key];
        prev[control.key] = {
          previewUrl: null,
          previewVisible: false,
          fileList: path != null ? [{
            name: path.split('/').pop(),
            uid: path,
            status: 'done',
            url: path
          }] : []
        };
        return prev;
      }, {});
    },

    /**
     * 处理文件上传预览
     */
    async handleUploadPreview(file, control) {
      if (control.config.format === 'compress') {
        return;
      }
      if (!file.url && !file.preview) {
        file.preview = URL.createObjectURL(file.originFileObj);
      }

      this.fileUploadMap[control.key].previewUrl = file.url || file.preview;
      this.fileUploadMap[control.key].previewVisible = true;
    },

    /**
     * 处理文件上传状态变更
     */
    handleUploadChange(control, info) {
      this.fileUploadMap[control.key].fileList = info.fileList;
      const { status, response } = info.file;
      if (status === 'done') {
        this.form[control.key] = response.url;
      } else if (status === 'error') {
        this.$message.error(typeof response === 'string' ? response : response.message);
      }

      if (status === 'removed' && info.fileList.length === 0) {
        this.form[control.key] = null;
      }
    },

    /**
     * 限制文件上传格式与大小
     */
    onBeforeUpload(control, file) {
      const ext = file.name.split('.').pop();
      if (!control.config.accept.includes(ext)) {
        file.status = 'error';
        file.response = '文件格式有误';
        return false;
      }

      if (file.size > control.config.maxSize) {
        file.status = 'error';
        file.response = '文件大小不能超过 ' + formatBytes(control.config.maxSize);
        return false;
      }

      return true;
    },
    closeModal() {
      this.openModal = false;
    },
    //--------------------------------------------
    openDrawer() {
      this.transferData = [];
      if (this.action.isSelected && (this.selectedRows.length == 0 || this.selectedRows[0] == undefined)) {
        this.$message.error('请勾选要编辑的内容');
        return
      }
      if (this.action.determine) {
        let modalObj = this.action.modalObj(this.selectedRows, this.tableData);
        if (modalObj.showMOdal) {
          this.modalTitle = modalObj.title;
          this.modalContent = modalObj.content;
          this.openModal = true;
        } else {
          this.modalContent = modalObj
        }
      };
      getProfile()
        .then((result) => {
          if (!result.everyPermissions(this.action.permission)) {
            this.$message.error("没有权限访问");
            return;
          }
          this.loadInitData();
          this.drawerVisible = true;
        })
    },

    closeDrawer() {
      this.drawerVisible = false;
      this.fileUploadMap = this.initFileUploadMap();
      this.$refs.form.resetFields();
      this.currentStep = 0;
      this.controls.filter(control => {
        if (control.type === "mSelect") {
          this.form[control.key] = [];
        }
      })
    },

    async loadInitData() {
      if (this.action.config.initUrl == null) {
        this.initDataReady = true;
        return;
      }
      this.initLoading = true;
      try {
        this.initData = await get(this.action.config.initUrl, {
          id: this.selectedRows.map(({ id }) => id)
        });
        this.initDataReady = true;
        if (this.action.config.controls.findIndex(item => item.type === 'transfer') > -1) {
          const index = this.action.config.controls.findIndex(item => item.type === 'transfer');
          this.getTransferData(this.action.config.controls[index].cascadeData)
        }
      } catch (e) {
        this.$message.error(e.message);
      }
      this.initLoading = false;
      this.form = this.initForm();
      this.fileUploadMap = this.initFileUploadMap();
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }

      this.submitLoading = true;
      let methods = 'post'
      if (this.action.config.methods) {
        methods = this.action.config.methods
      }
      try {
        await this.$http[methods](this.action.config.submitUrl, this.selectedRows.length > 0 ? {
          ...this.getForm(),
          id: this.selectedRows.map(({ id }) => id)
        } : (this.action.config.isFilter ? {
          ...this.filterForm,
          ...this.getForm(),
        } : this.getForm()));
        this.$message.success(this.action.config.submitSuccessMessage ?? '操作成功');

        this.closeDrawer();
        this.$emit('change');
      } catch ({ message }) {
        let type = message.slice(0, 6);
        if (type == 'type=1') {
          this.modalContent = message.slice(7);
          this.openModal = true;
          this.modalTitle = "提示";
        } else {
          this.$message.error(message);
        }
      }
      this.submitLoading = false;
    },

    getPlaceholder(control) {
      return (['date', 'datetime', 'select', 'mSelect', 'souSelect', 'dateRange'].includes(control.type) ? '选择' : control.type === 'file' ? '上传' : '输入') + control.label;
    },

    getTreeData(control) {
      if (typeof control.config.options === 'function') {
        return control.config.options({
          initData: this.initData
        });
      }
      return control.config.options;
    },

    getMSelectData(data, control) {
      if (control.disabled) {
        let renderData = data[control.config.options];
        let haveData = data.source[control.key];
        renderData.map((item) => {
          item.disabled = haveData.indexOf(item.id) > -1;
        })
        return renderData
      }
      else if (control.dealData) {
        return control.config.options
      }
      else {
        return data[control.config.options]
      }

    },
    getStepsData(data, control) {
      if (data.source) {
        control.config.options.map((item, index) => {
          if (item.value === data.source[control.key]) {
            this.currentStep = index;
            control.config.options = control.config.options.map((i, idx) => {
              if (index < control.config.options.length - 2 && idx < index) {
                return {
                  ...i,
                  status: 'finish',
                  disabled: false
                };
              }
              if (index >= control.config.options.length - 2 && idx < index) {
                return {
                  ...i,
                  status: 'finish',
                  disabled: true
                };
              }
              if (idx >= control.config.options.length - 2) {
                return {
                  ...i,
                  disabled: true,
                  status: '',
                }
              }
              return {
                ...i,
                disabled: false,
                status: '',
              }
            });

          }
        })
      } else {
        control.config.options = control.config.options.map((item, index) => {
          if (this.currentStep < control.config.options.length - 2 && index < this.currentStep) {
            return {
              ...item,
              status: 'finish',
              disabled: false
            };
          }
          if (this.currentStep >= control.config.options.length - 2 && index < this.currentStep) {
            return {
              ...item,
              status: 'finish',
              disabled: true
            };
          }
          if (index >= control.config.options.length - 2) {
            return {
              ...item,
              disabled: true,
              status: '',
            }
          }
          return {
            ...item,
            disabled: false,
            status: '',
          }
        });
      }
      // control.config.options = control.config.options.map((item, index) => {
      //   if (this.current < control.config.options.length - 2 && index < this.current) {
      //     return {
      //       ...item,
      //       status: 'finish',
      //       disabled: false
      //     };
      //   }
      //   if (this.current >= control.config.options.length - 2 && index < this.current) {
      //     return {
      //       ...item,
      //       status: 'finish',
      //       disabled: true
      //     };
      //   }
      //   if (index >= control.config.options.length - 2) {
      //     return {
      //       ...item,
      //       disabled: true,
      //       status: '',
      //     }
      //   }
      //   return {
      //     ...item,
      //     disabled: false,
      //     status: '',
      //   }
      // });
      return control.config.options

    },
    handleChange(value) {
      console.log(`${value}`);
    },

    getForm() {
      return this.action.config.controls.reduce((prev, control) => {
        const value = this.form[control.key];
        if (value == null) {
          return prev;
        }
        if (control.type === 'date') {
          if (control.showNow) {
            prev[control.key] = value.format('YYYY-MM-DD HH:mm:ss');
          } else {
            prev[control.key] = value.format('YYYY-MM-DD') + (control.options && control.options.endOfDay ? ' 23:59:59' : ' 00:00:00');
          }
        } else if (control.type === 'datetime') {
          prev[control.key] = value.format('YYYY-MM-DD HH:mm:ss');
        } else if (control.type === 'dateRange') {
          prev[control.config.startKey ?? 'start' + capitalize(control.key)] = value[0]?.format('YYYY-MM-DD HH:mm:ss');
          prev[control.config.endKey ?? 'end' + capitalize(control.key)] = value[1]?.format('YYYY-MM-DD HH:mm:ss')
        } else {
          prev[control.key] = value;
        }
        return prev;
      }, {});
    },

    async getTransferData(cascadeData) {
      try {
        let { institutionsList } = await this.$http.get(cascadeData.cascadeUrl, {
          countryOfApplicationContainsIn: ''
        });
        // this.transferData = [];
        this.action.config.controls.map(item => {
          if (item.key === cascadeData.cascadeKey) {
            if (this.initData.source.list.length > 0) {
              institutionsList.map(i => {
                this.transferData.push({
                  key: i.id.toString(),
                  title: i.name,
                  disabled: this.initData.source.list.indexOf(i.id) > -1,
                })
              })
            } else {
              institutionsList.map(i => {
                this.transferData.push({
                  key: i.id.toString(),
                  title: i.name,
                  disabled: false,
                })
              })
            }
          }
        })
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    // 联级操作查询transfer数据
    async cascadeSelect(value, isCascade, cascadeData) {
      if (isCascade) {
        try {
          let { institutionsList } = await this.$http.get(cascadeData.cascadeUrl, {
            countryOfApplicationContainsIn: value
          });
          this.transferData = [];
          this.action.config.controls.map(item => {
            if (item.key === cascadeData.cascadeKey) {
              if (this.initData.source.list.length > 0) {
                institutionsList.map(i => {
                  this.transferData.push({
                    key: i.id.toString(),
                    title: i.name,
                    disabled: this.initData.source.list.indexOf(i.id) > -1,
                  })
                })
              } else {
                institutionsList.map(i => {
                  this.transferData.push({
                    key: i.id.toString(),
                    title: i.name,
                    disabled: false,
                  })
                })
              }
            }
          })
        } catch ({ message }) {
          this.$message.error(message);
        }
      }
    },
  }
}
</script>
<style scoped lang="scss">
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.formCon {
  margin-top: 20px;
}
</style>
