import department from "./department";
import employee from "./employee";
import role from "./role";
import tags from "./tags";
import autoAllocation from "./autoAllocation";
import operationLog from "./operationLog";
export default [
  department,
  employee,
  role,
  tags,
  autoAllocation,
  operationLog
];
