export default {
  key: '/dataReport/followData',
  title: '跟进人数据',
  permission: '/dataFollowerUp/list',
  type: 'table',
  config: {
    dataUrl: '/dataFollowerUp/list',
    filter: {
      initUrl: "/dataFollowerUp/list/get",
      controls: [
        {
          key: 'coreDataDate',
          label: '时间',
          type: 'dateRange',
          resetDisabled: true,
          config: {
            initialValue: '当月',
          }
        },
        {
          key: 'departmentIdIs',
          label: '部门',
          type: 'select',
          config: {
            options: 'departmentList'
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出Excel',
        type: 'url',
        permission: '/dataFollowerUp/export',
        config: {
          title: '导出Excel',
          color: 'primary',
        },
        options: {
          url: '/api/dataFollowerUp/export',
          params: true,
          newPage: true
        }
      },
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: [''],
      /* 默认排序 */
      defaultSort: ''
    },
    columns: [
      {
        key: 'username',
        title: '姓名',
        width: '150px'
      },
      {
        key: 'departmentName',
        title: '所属部门',
        width: 150,
      },
      {
        key: 'followerUpNum',
        title: '跟进客资数',
        width: 150,
        popover: '按客资接收时间统计'
      },
      {
        key: 'accurateNum',
        title: '精准客资数',
        width: 150,
        popover: '按客资转为精准客资时间统计'
      },
      {
        key: 'signOrderNum',
        title: '签单数',
        width: 150,
        popover: '按签单时间统计'
      },
      {
        key: 'signOrderAmount',
        title: '签单额(元)',
        width: 150,
        type: 'numeric',
        popover: '按签单时间统计',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'receivedPaymentAmount',
        title: '回款(元)',
        width: 150,
        type: 'numeric',
        popover: '按回款时间统计',
        config: {
          format: 'currency'
        }
      }
    ],

    /* 表格行操作 */
    rowActions: [

    ]
  }
}