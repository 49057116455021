import { intentionLevel, serviceItems } from '@/utils/public';
export default {
  key: '/bom/followup',
  title: '跟进记录',
  type: 'table',
  permission: '/institutions/followUp/list',
  config: {
    dataUrl: '/institutions/followUp/list',
    filter: {
      initUrl: '/institutions/followUp/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'followUpPeopleIdIs',
          label: '跟进人',
          type: 'select',
          config: {
            options: 'followUpPeopleList'
          }
        },
        {
          key: 'followUpTime',
          label: '跟进时间',
          type: 'dateRange'
        },
        {
          key: 'intentionLevelIn',
          label: '意向等级',
          type: 'mSelect',
          width: '200',
          config: {
            options: intentionLevel
          }
        },
      ]
    },

    actions: [
      {
        key: 'chapter',
        text: '导出Excel',
        type: 'url',
        permission: '/institutions/followUp/export',
        config: {
          title: '导出Excel',
          color: 'primary',
        },
        options: {
          url: '/api/institutions/followUp/export',
          params: true,
          newPage: true
        }
      },
    ],
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['followUpTime'],
      /* 默认排序 */
      defaultSort: '-followUpTime'
    },
    columns: [
      {
        key: 'institutionsCustomerRelationship.name',
        title: '客户姓名',
        width: 150,
      },
      {
        key: 'institutionsCustomerRelationship.intentionLevel',
        title: '意向等级',
        width: 150,
        type: 'enum',
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            },
            null: {
              text: '无',
              color: 'grey'
            },
          }
        }
      },
      {
        key: 'followUpPeople.name',
        title: '跟进人',
        width: 150,
      },
      {
        key: 'signingItem.content',
        title: '预计签单项目',
        width: 150,
      },
      {
        key: 'followUpContent',
        title: '跟进内容',
        width: 150,
        ellipsis: true
      },
      {
        key: 'followUpTime',
        title: '跟进时间',
        type: 'datetime',
        width: 150,
      },

      {
        key: 'nextFollowUpTime',
        title: '下次跟进时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'createdDate',
        title: '记录创建时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'actions',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '70px' : '180px'

      },

    ],
    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/institutions/followUp/details',
        config: {
          title: '客户详情',
          component: () => import("./views/followupCusDetail")
        }
      },
      {
        key: 'add',
        text: '跟进',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/followUp/addFollowUp',
        config: {
          color: 'primary',
          title: '新增跟进记录',
          initUrl: '/institutions/followUp/addFollowUp/get',
          submitUrl: '/institutions/followUp/addFollowUp',
          submitText: '确认添加',
          submitSuccessMessage: (submitSuccessMessage) => {
            if (submitSuccessMessage.response.msg) {
              return `${submitSuccessMessage.response.msg}`
            } else {
              return '操作成功'
            }
          },
          verify: {
            submitUrl: "/institutions/followUp/addFollowUp",
            secondUrl: "/institutions/followUp/addFollowUpToHighSeas",
            needId: false,
            text: '该客资意向等级已修改为无效，要把客资移入客户公海吗？',
            condition: (forms) => {
              if (forms.intentionLevel == 'INVALID') {
                return true
              } else {
                return false
              }
            }
          },
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
              required: true,
            },
            {
              key: 'followUpTime',
              label: '跟进时间',
              type: 'date',
              disabledDate: true,
              today: true,
              required: true,
            },
            {
              key: 'nextFollowUpTime',
              label: '下次跟进时间',
              type: 'date',
              showNow: true,
              required: false,
              config: {
              }
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'accurateCustomer',
              label: '是否出合同或上门',
              type: 'radio',
              required: true,
              tips: '出合同或上门的客资即精准客资',
              config: {
                options: [
                  {
                    text: '否',
                    value: false,
                    noshow: ({ source }) => {
                      return source == true;
                    }
                  },
                  {
                    text: '是',
                    value: true,
                  },
                ]
              }
            },
            {},
            {
              key: 'signingItemId',
              label: '预计签单项目',
              type: 'select',
              required: true,
              isAssociate: true,
              config: {
                options: "signingItemList",
                textKey: 'content',
                hidden({ selectedRows, form, initData }) {
                  if (form.accurateCustomer == true) {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'estimatedSigningAmount',
              label: '预计签单金额',
              type: 'numeric',
              required: true,
              isAssociate: true,
              config: {
                format: 'rmby',
                hidden({ selectedRows, form, initData }) {
                  if (form.accurateCustomer == true) {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'followUpContent',
              label: '跟进内容',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ],
              }
            },
          ]
        }
      },
      {
        key: 'add',
        text: '成单',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/followUp/addOrder',
        needPermission: true,
        showPermission:(e)=>{
          if(e !== 1) return true
        },
        disabled: ({ selectedRows }) => {
          return selectedRows[0].institutionsCustomerRelationship.status != 'NORMAL';
        },
        config: {
          color: 'primary',
          title: '新增订单',
          initUrl: '/institutions/followUp/addOrder/get',
          submitUrl: '/institutions/followUp/addOrder',
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'signingTime',
              label: '签单时间',
              type: 'date',
              disabledDate: true,
              showNow: true,
              required: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              type: 'select',
              required: true,
              config: {
                options: "signingItemList",
                textKey: 'content'
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              required: true,
              config: {
                format: 'currency',
                max: (form) => { }
              }
            },
            {
              key: 'attributionId',
              label: '订单归属人',
              type: 'select',
              required: true,
              config: {
                options: 'attributablePersonList'
              }
            },
            {
              key: 'followUpWithPeopleId',
              label: '订单跟进人',
              type: 'select',
              required: false,
              authRequired: {
                institution: 1,
                type: true
              },
              config: {
                options: 'followUpPeopleIdList'
              }
            },
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
    ]
  }
};
