// vue3中创建store实例对象的方法createStore()按需引入
import { createStore } from 'vuex'

export default createStore({
  state: {
    onlineStatus: '',
  },
  mutations: {
    CHANGE_ONLINESTATUS: (state, onlineStatus) => {
      state.onlineStatus = onlineStatus
    },
  },
  actions: {
    changeOnlineStatus({ commit }, data) {
      commit('CHANGE_ONLINESTATUS', data)
    },
  },
  getters: {

  },
  modules: {
  }
})

