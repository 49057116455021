<template>
  <div class="inline">
    <span v-if="disabledValue !== 'false'">
      <a-button v-if="type === 'button'" :type="action.config.color" @click="handleUrlAction(action.options)"
        :disabled="!disabledValue">
        <template #icon>
          <component v-if="action.icon" :is="$icons[action.icon]"></component>
        </template>
        {{ action.text }}</a-button>
      <a-button v-else-if="type === 'link'" @click="handleUrlAction(action.options, selectedRows)"
        :class="{ 'text-danger': action.config.color === 'danger' }" :disabled="!disabledValue" type="link">
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ action.text }}
      </a-button>
    </span>


    <!-- <a-drawer :title="action.config.title" placement="right" :closable="false" :destroy-on-close="true" width="50%"
      :visible="drawerVisible" @close="closeDrawer">
      <component v-if="components != null" :is="components" :selected-rows="selectedRows" @close="closeAndFetch" />
    </a-drawer> -->
  </div>
</template>

<script>
import { getProfile } from "@/utils/session";
import { shallowRef } from "vue";
import { toRaw } from '@vue/reactivity';
import dayjs from "dayjs";
export default {
  name: "BlankAction",
  props: ['action', 'type', 'selectedRows', 'disabledValue', 'filterForm', 'listFilterKey'],
  data() {
    return {
      drawerVisible: false,
      components: null
    }
  },
  computed: {
    config() {
      return this.action.config;
    },

    // filterForm() {
    //   return this.filterForm;
    // }

    // disabled() {
    //   if (this.action.disabled == null) {
    //     return false;
    //   }

    //   if (typeof this.action.disabled === 'function') {
    //     return this.action.disabled({
    //       selectedRows: this.selectedRows
    //     });
    //   }
    //   return this.action.disabled;
    // },

    // props() {
    //   if (this.type === 'button') {
    //     return {
    //       is: 'a-button',
    //       disabled: this.disabled,
    //       type: this.action.config.color,
    //       icon: this.action.icon
    //     };
    //   }
    //   return {
    //     is: 'a',
    //     class: { 'text-danger': this.action.config.color === 'danger' },
    //     disabled: this.disabled
    //   };
    // }
  },

  methods: {
    handleUrlAction(action, rows) {
      const urlStr = action.url.replace(/{(\w+)}/g, (substr, group) => rows[0][group]);
      const url = new URL(/^https?:\/\//.test(urlStr) ? urlStr : window.location.origin + urlStr);
      if (action.params === true) {
        const listForm = this.filterForm;
        Object.keys(listForm).forEach(key => {
          const value = listForm[key];
          if (Array.isArray(value) && toRaw(value[0]).$d) {
            const onOrAfter = dayjs(toRaw(value[0]).$d).format('YYYY-MM-DD') + ' 00:00:00';
            const onOrBefore = dayjs(value[1].$d).format('YYYY-MM-DD') + ' 23:59:59';
            url.searchParams.append(`${key}OnOrAfter`, onOrAfter);
            url.searchParams.append(`${key}OnOrBefore`, onOrBefore);
          } else {
            url.searchParams.append(key, listForm[key]);
          }
        });
        if(this.listFilterKey) {
          url.searchParams.append('quickCheck', this.listFilterKey);
        }
        // url.searchParams.append('id', rows[0].id);
      }
      if (action.newPage) {
        window.open(url.toString());
      } else {
        window.location.href = url.toString();
      }
    },
    async openDrawer() {
      getProfile()
        .then(async (result) => {

          if (!result.everyPermissions(this.action.permission)) {
            this.$message.error("没有权限访问");
            return;
          }
          this.drawerVisible = true;
          if (this.components == null) {
            this.components = shallowRef((await this.config.component()).default);
          }
        })
    },


    closeDrawer() {
      this.drawerVisible = false;
    },
    closeAndFetch() {
      this.$emit('change');
      this.drawerVisible = false;
    }

  }
}
</script>
