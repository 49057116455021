import { orderInProgressNew } from '@/utils/public';
export default {
  key: '/postService/postService',
  title: '后期服务',
  type: 'table',
  permission: '/institutions/aftermarket/list',
  config: {
    dataUrl: '/institutions/aftermarket/list',
    filter: {
      initUrl: "/institutions/aftermarket/listEmployeeIdAndName",
      controls: [
        {
          key: 'customerName',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'serviceEmployeeId',
          label: '服务人',
          type: 'souSelect',
          config: {
            options: "data"
          }
        },
        {
          key: 'createTime',
          label: '创建时间',
          type: 'dateRange',
        },
        {
          key: 'endYear',
          label: '结束时间',
          type: 'dateRangeYear',
        },
        {
          key: 'paymentStatus',
          label: '订单支付状态',
          type: 'select',
          config: {
            options: orderInProgressNew
          }
        },
        {
          key: 'applicantName',
          label: '申请人姓名',
          type: 'text'
        },
      ]
    },

    listFilter: {
      key: 'quickCheck',
      defaultValue: null,
      options: [
        {
          value: null,
          name: '全部'
        },
        {
          value: 1,
          name: '进行中的服务'
        },
        {
          value: 2,
          name: '完结的服务'
        },
        {
          value: 3,
          name: '取消的服务'
        }
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出数据',
        type: 'url',
        permission: '/institutions/aftermarket/export',
        config: {
          title: '导出数据',
          color: 'primary',
        },
        options: {
          url: '/api/institutions/aftermarket/export',
          params: true,
          newPage: true
        }
      },
      {
        key: 'edit',
        text: '设置服务人',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/institutions/aftermarket/editServicePerson',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 条客资，请选择服务人`
        },
        config: {
          title: '设置服务人',
          initUrl: '/institutions/aftermarket/listEmployeeIdAndName',
          submitUrl: '/institutions/aftermarket/editServicePerson',
          submitText: '确认',
          controls: [
            {
              key: 'servicePersonIds',
              label: '服务人',
              type: 'mSelect',
              required: true,
              config: {
                options: "data"
              }
            },
          ]
        }
      },
    ],

    columns: [
      {
        key: 'serviceNumber',
        title: '服务号',
        width: '130px',
      },
      {
        key: 'customerName',
        width: '150px',
        title: '客户姓名',
      },
      {
        key: 'customerStar',
        width: '100px',
        title: '客户星级',
        type: 'textCustom',
        config: {
          text: (val) => {
            let star = ''
            switch (val) {
              case 'ONE_STAR':
                star = '一星'
                break;
              case 'TWO_STARS':
                star = '二星'
                break;
              case 'THREE_STARS':
                star = '三星'
                break;
              case 'FOUR_STARS':
                star = '四星'
                break;
              case 'FIVE_STARS':
                star = '五星'
                break;
              default:
                star = '-'
                break;
            }
            return star
          }
        }
      },
      {
        key: 'serviceEmployees',
        width: '150px',
        title: '服务人',
      },
      {
        key: 'applicantName',
        width: '150px',
        title: '申请人姓名',
      },
      {
        key: 'applicationNumber',
        width: '100px',
        title: '申请项目数',
      },
      {
        key: 'createdDate',
        title: '创建时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'endYear',
        width: '150px',
        title: '结束时间',
        type: 'customizeText',
        customize: (val) => {
          return (val.endYear ? (val.endYear + '年') : '') + (val.endSeasonName ? val.endSeasonName : '')
        }
      },
      {
        key: 'paymentStatus',
        width: '100px',
        title: '订单支付状态',
      },
      {
        key: 'admissionResult',
        title: '录取结果',
        width: '100px',
        ellipsis: true
      },
      {
        key: 'remark',
        title: '备注',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '100px' : '160px'
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: [''],
      /* 默认排序 */
      defaultSort: ''
    },

    checkbox: true,

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '编辑',
        type: 'blank',
        permission: '/institutions/aftermarket/edit',
        config: {
          title: '编辑',
          component: () => import("./views/editPostService")
        }
      },
      {
        key: 'detail',
        text: '服务详情',
        type: 'blank',
        permission: '/institutions/aftermarket/get',
        config: {
          title: '服务详情',
          component: () => import("./views/postServiceDetail")
        }
      },
    ]
  }
};
