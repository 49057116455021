<template>
  <a @click="showDrawer">
    我的推荐码
    <a-modal v-model:open="drawerVisible" title="我的推荐码" @ok="drawerVisible = false" @cancel="drawerVisible = false">
      <p style="margin-bottom: 20px;">分享"我的推荐码”邀请新机构申请时填入,并成功入驻留服有机会激活更多客资权益！</p>
      <p style="margin-bottom: 6px;">我的推荐码：</p>
      <div style="margin-bottom: 20px;">
        <span ref="contentToCopy" style="margin-right: 20px;">{{ refInfos.refCode }}</span>
        <span @click="copyToClipboard" style="color: #1677ff; cursor: pointer;">复制</span>
      </div>
      <p style="margin-bottom: 6px;">推荐我入驻的机构：</p>
      <div style="margin-bottom: 20px;">
        <span v-if="step == 1 && !refInfos.superiorInstitution" style="margin-right: 20px;">暂无</span>
        <span v-if="refInfos.superiorInstitution" style="margin-right: 20px;">{{ refInfos.superiorInstitution }}</span>
        <span v-if="step == 1 && !refInfos.superiorInstitution" @click="step = 2"
          style="color: #1677ff; cursor: pointer;">关联推荐机构</span>
        <div v-if="step == 2" style="display: flex; align-items: center;">
          <a-input style="width: 140px; margin-right: 12px;" v-model:value="refCode" placeholder="请输入推荐码" />
          <span @click="submit" style="color: #1677ff; cursor: pointer;">确定</span>
        </div>
      </div>
      <p style="margin-bottom: 6px;">我推荐入驻的机构：</p>
      <div v-if="subordinateInstitution.length > 0" style="margin-bottom: 20px;">
        <p v-for="(item, index) in subordinateInstitution">{{ item }}</p>
      </div>
      <div v-else style="margin-bottom: 20px;">
        <p>暂无</p>
      </div>
    </a-modal>
  </a>
</template>

<script>
import { clearProfile } from "@/utils/session";

export default {
  name: "RecommendationCode",
  data() {
    return {
      drawerVisible: false,
      refInfos: {},
      subordinateInstitution: [],
      step: 1,
      refCode: ''
    }
  },
  mounted() {
    this.refInfo()
  },
  methods: {
    showDrawer() {
      this.drawerVisible = true;
    },

    hideDrawer() {
      this.drawerVisible = false;
      this.$refs.form.resetFields();
    },

    async refInfo() {
      try {
        let res = await this.$http.get('/system/institutionsEmployee/refInfo');
        this.refInfos = res.data;
        this.subordinateInstitution = res.data.subordinateInstitution;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async submit() {
      if(!this.refCode) {
        this.$message.error('请输入推荐码');
      }
      try {
        await this.$http.post('/system/institutionsEmployee/setSuperior', {
          refCode: this.refCode
        });
        this.$message.success('关联成功');
        this.refInfo();
        this.step = 1;
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    copyToClipboard() {
      const contentToCopy = this.$refs.contentToCopy;
      const range = document.createRange();
      range.selectNode(contentToCopy);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      try {
        document.execCommand('copy');
        this.$message.success('内容已成功复制到粘贴板');
      } catch (e) {
        console.error('复制失败', e);
      }
      selection.removeAllRanges();
    },
  }
}
</script>

<style scoped></style>
