import { tdCompanys, lookType } from '@/utils/public';
export default {
  key: '/system/employee',
  title: '账号管理',
  type: 'table',
  permission: '/system/institutionsEmployee/list',
  config: {
    dataUrl: '/system/institutionsEmployee/list',
    filter: {
      initUrl: '/system/institutionsEmployee/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '账号',
          type: 'text'
        },
        {
          key: 'departmentNameContains',
          label: '部门',
          type: 'text'
        },
        {
          key: 'institutionsRoleIdIs',
          label: '选择角色',
          type: 'select',
          config: {
            options: 'roles',
          }
        },
      ]
    },
    treeFilter: {
      controls: [
        {
          key: 'departmentIdIs',
          label: '',
          type: 'tree',
          config: {
            optList: 'departments',
            options: [],
          }
        },
      ]
    },
    actions: [
      {
        key: 'add',
        text: '添加账号',
        icon: 'plus',
        type: 'form',
        permission: '/system/institutionsEmployee/add',
        config: {
          color: 'primary',
          title: '添加账号',
          initUrl: '/system/institutionsEmployee/add/get',
          submitUrl: '/system/institutionsEmployee/add',
          // submitSuccessMessage: "添加成功,默认密码:000000",
          submitSuccessMessage: (submitSuccessMessage) => {
            return `添加账号成功！随机默认密码：${submitSuccessMessage.response.password}`
          },
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '姓名',
              type: 'text',
              required: true,
              tips: '员工在系统内显示的名字',
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-20个字符' }
                ]
              }
            },
            // {
            //   key: 'username',
            //   label: '用户名',
            //   type: 'text',
            //   required: true,
            //   tips: '员工登录系统时的账号',
            //   config: {
            //     rules: [
            //       { min: 1, max: 30, message: '只能输入1-20个字符' }
            //     ]
            //   }
            // },
            {
              key: 'phoneNumber',
              label: '手机号(登录账号)',
              type: 'text',
              required: true,
              config: {
                rules: ['phoneNumber']
              }
            },
            {
              key: 'smsCode',
              label: '验证码',
              type: 'code',
              required: true,
              config: {
                phone: 'phoneNumber',
                url: '/auth/getSmsCode'
              }
            },
            {
              key: 'institutionsRoleId',
              label: '角色',
              type: 'select',
              tips: '角色决定了员工能使用的系统功能',
              config: {
                options: "roles"
              }
            },
            
            {
              key: 'departmentId',
              label: '所属部门',
              type: 'treeSelect',
              config: {
                options: "departments"
              }
            },
            {
              key: 'company',
              label: '所属公司',
              type: 'select',
              config: {
                options: tdCompanys,
                hidden({ institutionsName }) {
                  if (institutionsName == 1) {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'online',
              label: '在线状态',
              type: 'select',
              config: {
                initialValue: "true",
                options: [
                  {
                    text: '在线',
                    value: "true"
                  },
                  {
                    text: '离线',
                    value: "false"
                  }
                ]
              }
            },
            {
              key: 'desensitization',
              label: '列表联系方式查看',
              type: 'select',
              tips: '控制列表上用户手机号和微信号的明密文显示。例:电话号的密文显示为178****8888',
              required: true,
              config: {
                options: [
                  {
                    text: '明文显示',
                    value: 'false'
                  },
                  {
                    text: '密文显示',
                    value: 'true'
                  }
                ]
              }
            },
          ]
        }
      },
      {
        key: 'detail',
        text: '更换超管',
        type: 'blank',
        permission: '/system/institutionsEmployee/replaceAdmin',
        config: {
          title: '更换超管',
          color: 'primary',
          component: () => import("./views/supertube")
        }
      },
    ],

    columns: [
      {
        key: 'name',
        title: '姓名',
        width: 150,
      },
      // {
      //   key: 'username',
      //   title: '账号',
      //   width: 150,
      // },
      {
        key: 'phoneNumber',
        title: '手机号(登录账号)',
        width: 150,
      },
      {
        key: 'institutionsRole.name',
        type: 'customizeText',
        title: '角色',
        width: 150,
        customize: (row) => {
          if (row.admin) {
            return '超级管理员'
          } else {
            return row.institutionsRole.name
          }
        }
      },
      {
        key: 'department.name',
        title: '部门',
        width: 150,
      },
      {
        key: 'company',
        title: '公司',
        columnsHidden: (name) => {
          if (name == 1) {
            return false
          } else {
            return true
          }

        },
        width: 150,
      },
      {
        key: 'locked',
        title: '账号状态',
        type: 'enum',
        width: 150,
        config: {
          constants: {
            false: {
              text: '正常',
              color: 'orange'
            },
            true: {
              text: '作废',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'online',
        title: '在线状态',
        type: 'textEnum',
        width: 150,
        config: {
          constants: {
            true: '在线',
            false: '离线'
          }
        }
      },
      // {
      //   key: 'phoneNumber',
      //   title: '手机号',
      //   width: 150,
      // },
      {
        key: 'createdDate',
        title: '注册时间',
        type: 'datetime',
        width: 120,
      },

      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '200px',
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 默认排序 */
      defaultSort: '-id'
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        permission: '/system/institutionsEmployee/edit',

        config: {
          color: 'primary',
          title: '编辑账号',
          initUrl: '/system/institutionsEmployee/edit/get',
          submitUrl: '/system/institutionsEmployee/edit',
          submitText: '确认修改',
          controls: [
            {
              key: 'name',
              label: '姓名',
              type: 'text',
              required: true,
              tips: '员工在系统内显示的名字',
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-20个字符' }
                ]
              }
            },
            // {
            //   key: 'username',
            //   label: '用户名',
            //   type: 'text',
            //   required: true,
            //   tips: '员工登录系统时的账号',
            //   config: {
            //     rules: [
            //       { min: 1, max: 30, message: '只能输入1-20个字符' }
            //     ]
            //   }
            // },
            {
              key: 'phoneNumber',
              label: '手机号(登录账号)',
              type: 'text',
              required: false,
              disabled: (row, key) => {
                return false
              },
              config: {
                rules: ['phoneNumber']
              }
            },
            {
              key: 'institutionsRoleId',
              label: '角色',
              type: 'select',
              tips: '角色决定了员工能使用的系统功能',
              isDisabled: (row, key) => {
                if (row[0].admin) return true
              },
              config: {
                options: "roles"
              }
            },
            {
              key: 'departmentId',
              label: '所属部门',
              type: 'treeSelect',
              config: {
                options: "departments"
              }
            },
            {
              key: 'company',
              label: '所属公司',
              type: 'select',
              config: {
                options: tdCompanys,
                hidden({ institutionsName }) {
                  if (institutionsName == 1) {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'online',
              label: '在线状态',
              type: 'select',
              config: {
                options: [
                  {
                    text: '在线',
                    value: true
                  },
                  {
                    text: '离线',
                    value: false
                  }
                ]
              }
            },
            {
              key: 'desensitization',
              label: '列表联系方式查看',
              type: 'select',
              tips: '控制列表上用户手机号和微信号的明密文显示。例:电话号的密文显示为178****8888',
              required: true,
              config: {
                options: [
                  {
                    text: '明文显示',
                    value: false
                  },
                  {
                    text: '密文显示',
                    value: true
                  }
                ]
              }
            },
          ]
        }
      },
      // {
      //   key: 'assigningRoles',
      //   text: '分配角色',
      //   type: 'form',
      //   permission: '/system/employee-role/edit',
      //   config: {
      //     color: 'primary',
      //     title: '分配角色',
      //     initUrl: "/system/employee-role/edit/get",
      //     submitUrl: "/system/employee-role/edit",
      //     submitText: '确认分配',
      //     controls: [
      //       {
      //         key: 'roleIds',
      //         label: '角色',
      //         type: 'checkbox',
      //         required: true,
      //         config: {
      //           options: 'roleList',
      //           layout: 'vertical'
      //         }
      //       },
      //     ]
      //   }
      // },
      {
        key: 'lock',
        text: '作废',
        type: 'confirm',
        permission: '/system/institutionsEmployee/lock',
        disabled: ({ selectedRows }) => {
          return selectedRows[0].locked === false;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/system/institutionsEmployee/lock'
        }
      },
      {
        key: 'unlock',
        text: '恢复',
        type: 'confirm',
        permission: '/system/institutionsEmployee/unlock',
        disabled({ selectedRows }) {
          return selectedRows[0].locked === true;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/system/institutionsEmployee/unlock'
        }
      },
      {
        key: 'reset-password',
        text: '重置密码',
        type: 'confirm',
        permission: '/system/institutionsEmployee/reset-password',
        disabled: true,
        config: {
          color: 'danger',
          title: '确定对该条记录进行重置密码操作吗？',
          submitUrl: '/system/institutionsEmployee/reset-password',
          submitText: '确认',
          submitColor: 'danger',
          submitSuccessMessage: (submitSuccessMessage) => {
            return `重置密码成功，随机默认密码：${submitSuccessMessage.response.newPassword}`
          },
          submitLoadingMessage: '正在重置密码 ...'
        }
      },
    ]
  }
};
