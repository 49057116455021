<template>
  <div v-if="!$isMobile" class="register">
    <div>
      <img src="../assets/img/pc-login-logo.png" style="margin: 40px; width: 200px;" alt="">
    </div>
    <div class="login-body">
      <img src="../assets/img/pc-login-banner.png" style="width: 400px; margin-right: 200px;" alt="">
      <a-card class="lb-card">
        <div v-if="step != 3">
          <LeftOutlined @click="back" style="font-size: 16px; color: #666666;" />
        </div>
        <div v-if="step == 1">
          <p style="font-size: 20px; font-weight: 600; margin-top: 20px;">完成机构账号注册，</p>
          <p style="font-size: 20px; font-weight: 600; margin-top: 6px; margin-bottom: 40px;">开启留服搜索免费推客！</p>
          <a-form ref="form" layout="vertical" :model="form">
            <a-form-item label="" name="phoneNumber"
              :rules="[{ required: true, message: '输入手机号' }, { pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
              <a-input v-model:value.trim="form.phoneNumber" placeholder="输入手机号">
              </a-input>
            </a-form-item>
            <a-form-item label="" name="smsCode" :rules="[{ required: true, message: '输入验证码' }]">
              <div class="mr-ve">
                <a-input v-model:value.trim="form.smsCode" placeholder="输入验证码">
                </a-input>
                <span v-if="!validate" @click="getCountDown" class="re-code">获取验证码</span>
                <span v-else class="re-code-num">{{ countodwn }} S</span>
              </div>
            </a-form-item>
            <a-form-item label="" name="password"
              :rules="[{ required: true, message: '输入密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
              <a-input-password v-model:value.trim="form.password" placeholder="输入密码">
              </a-input-password>
            </a-form-item>
            <a-form-item label="" name="rePassword"
              :rules="[{ required: true, message: '输入确认密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
              <a-input-password v-model:value.trim="form.rePassword" placeholder="输入确认密码">
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <div style="display: flex; justify-content: center; margin-top: 30px;">
                <a-button class="start" block size="large" type="primary" @click="register">注册</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div v-if="step == 2">
          <p style="font-size: 22px; font-weight: 600; margin-top: 20px;">填写入驻信息</p>
          <p style="color: #666666; margin-top: 6px; margin-bottom: 20px;">方便后续我们与您确定合作细节</p>
          <a-form ref="settleForm" layout="vertical" :model="settleForm">
            <div class="step-two">
              <a-form-item label="公司主体" name="companySubject" :rules="[{ required: true, message: '公司主体' }]">
                <a-input v-model:value.trim="settleForm.companySubject" placeholder="输入公司主体">
                  <template #prefix>
                    <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="机构名称" name="name" :rules="[{ required: true, message: '机构名称' }]">
                <a-input v-model:value.trim="settleForm.name" placeholder="输入机构名称">
                  <template #prefix>
                    <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="城市" name="provinceCityDistrict" :rules="[{ required: true, message: '机构城市' }]">
                <a-cascader v-model:value="settleForm.provinceCityDistrict" :show-search="{ filter }"
                  :change-on-select="false" :options="citys" placeholder="请选择或搜索城市" />
              </a-form-item>
              <a-form-item label="业务类型" name="businessType" :rules="[{ required: true, message: '业务类型' }]">
                <a-select mode="multiple" v-model:value="settleForm.businessType" placeholder="请选择或搜索业务类型" allow-clear
                  style="width: 100%">
                  <a-select-option v-for="option in companyEntity" :key="option.id" :name="option.name"
                    :disabled="option.disabled">{{
    option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="联系人" name="contact" :rules="[{ required: true, message: '联系人' }]">
                <a-input v-model:value.trim="settleForm.contact" placeholder="输入联系人">
                  <template #prefix>
                    <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="联系手机号" name="phoneNumber"
                :rules="[{ required: true, message: '联系手机号' }, { pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
                <a-input v-model:value.trim="settleForm.phoneNumber" placeholder="输入联系手机号">
                  <template #prefix>
                    <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="推荐码" name="refCode">
                <a-input v-model:value.trim="settleForm.refCode" placeholder="推荐码">
                  <template #prefix>
                    <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
                  </template>
                </a-input>
              </a-form-item>
            </div>
            <a-form-item>
              <div style="display: flex; justify-content: center;">
                <a-button class="start" block size="large" type="primary" @click="submit">提交</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div v-if="step == 3">
          <div class="submit-success">
            <div style="display: flex; align-items: center; flex-direction: column;">
              <img src="../assets/img/submit-success.png" style="width: 150px; margin-top: 40px;" alt="">
              <p style="font-size: 22px; font-weight: 600; margin-top: 20px;">恭喜您，提交成功！</p>
              <p style="color: #666666; font-size: 14px; padding: 24px 20px; text-align: center;">
                我们已收到您的入驻申请，稍后会有商务经理联系您，申请通过后开放系统使用权限
              </p>
            </div>
          </div>
          <div style="margin-top: 40px;">
            <a-steps :current="1" direction="horizontal" :responsive="false" label-placement="vertical"
              :items="items" />
          </div>
        </div>
      </a-card>
    </div>
  </div>
  <div class="m-register" v-else>
    <div v-if="step != 3" style="padding: 20px;">
      <LeftOutlined @click="back" style="font-size: 16px; color: #666666;" />
    </div>
    <div v-if="step == 1" style="padding: 0 20px;">
      <p style="font-size: 20px; font-weight: 600; margin-top: 20px;">完成机构账号注册，</p>
      <p style="font-size: 20px; font-weight: 600; margin-top: 6px; margin-bottom: 40px;">开启留服搜索免费推客！</p>
      <a-form ref="form" layout="vertical" :model="form">
        <a-form-item label="" name="phoneNumber"
          :rules="[{ required: true, message: '输入手机号' }, { pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
          <a-input v-model:value.trim="form.phoneNumber" placeholder="输入手机号">
          </a-input>
        </a-form-item>
        <a-form-item label="" name="smsCode" :rules="[{ required: true, message: '输入验证码' }]">
          <div class="mr-ve">
            <a-input v-model:value.trim="form.smsCode" placeholder="输入验证码">
            </a-input>
            <span v-if="!validate" @click="getCountDown" class="re-code">获取验证码</span>
            <span v-else class="re-code-num">{{ countodwn }} S</span>
          </div>
        </a-form-item>
        <a-form-item label="" name="password"
          :rules="[{ required: true, message: '输入密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
          <a-input-password v-model:value.trim="form.password" placeholder="输入密码">
          </a-input-password>
        </a-form-item>
        <a-form-item label="" name="rePassword"
          :rules="[{ required: true, message: '输入确认密码' }, { min: 6, max: 16, message: '只能输入6-16个字符' }]">
          <a-input-password v-model:value.trim="form.rePassword" placeholder="输入确认密码">
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <div style="display: flex; justify-content: center;">
            <a-button class="start" block size="large" type="primary" @click="register">注册</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="step == 2" style="padding: 0 20px;">
      <p style="font-size: 22px; font-weight: 600; margin-top: 20px;">填写入驻信息</p>
      <p style="color: #666666; margin-top: 6px; margin-bottom: 40px;">方便后续我们与您确定合作细节</p>
      <a-form ref="settleForm" layout="vertical" :model="settleForm">
        <a-form-item label="公司主体" name="companySubject" :rules="[{ required: true, message: '公司主体' }]">
          <a-input v-model:value.trim="settleForm.companySubject" placeholder="输入公司主体">
            <template #prefix>
              <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="机构名称" name="name" :rules="[{ required: true, message: '机构名称' }]">
          <a-input v-model:value.trim="settleForm.name" placeholder="输入机构名称">
            <template #prefix>
              <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item label="城市" name="provinceCityDistrict" :rules="[{ required: true, message: '机构城市' }]">
          <a-cascader v-model:value="settleForm.provinceCityDistrict" :show-search="{ filter }"
            :change-on-select="false" :options="citys" placeholder="请选择或搜索城市" />
        </a-form-item>
        <a-form-item label="业务类型" name="businessType" :rules="[{ required: true, message: '业务类型' }]">
          <a-select mode="multiple" v-model:value="settleForm.businessType" placeholder="请选择或搜索业务类型" allow-clear
            style="width: 100%">
            <a-select-option v-for="option in companyEntity" :key="option.id" :name="option.name"
              :disabled="option.disabled">{{
    option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系人" name="contact" :rules="[{ required: true, message: '联系人' }]">
          <a-input v-model:value.trim="settleForm.contact" placeholder="输入联系人">
            <template #prefix>
              <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="联系手机号" name="phoneNumber"
          :rules="[{ required: true, message: '联系手机号' }, { pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/, message: '请输入正确的手机号' }]">
          <a-input v-model:value.trim="settleForm.phoneNumber" placeholder="输入联系手机号">
            <template #prefix>
              <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="推荐码" name="refCode">
          <a-input v-model:value.trim="settleForm.refCode" placeholder="推荐码">
            <template #prefix>
              <user-outlined type="user" style="color:rgba(0,0,0,.25); display: none;" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <div style="display: flex; justify-content: center;">
            <a-button class="start" block size="large" type="primary" @click="submit">提交</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="step == 3">
      <div class="submit-success">
        <img src="../assets/img/logo-op.png" style="width: 75px;padding-left: 20px; padding-top: 30px;" alt="">
        <div style="display: flex; align-items: center; flex-direction: column;">
          <img src="../assets/img/submit-success.png" style="width: 150px; margin-top: 40px;" alt="">
          <p style="font-size: 22px; font-weight: 600; margin-top: 20px;">恭喜您，提交成功！</p>
          <p style="color: #666666; font-size: 14px; padding: 24px 20px; text-align: center;">
            我们已收到您的入驻申请，稍后会有商务经理联系您，申请通过后开放系统使用权限
          </p>
        </div>
      </div>
      <div style="margin-top: 40px;">
        <a-steps :current="1" direction="horizontal" :responsive="false" label-placement="vertical" :items="items" />
      </div>
    </div>
  </div>
</template>
<script>
import { citysMenus } from '@/city.js';
import { companyEntity } from '@/utils/public';
export default {
  name: "message",
  data() {
    return {
      companyEntity: companyEntity,
      citys: citysMenus,
      items: [
        {
          title: '账号注册',
          description: '',
        },
        {
          title: '入驻申请',
          description: '确定合作细节',
        },
        {
          title: '登录系统',
          description: '接收免费客资',
        },
      ],
      step: 1,
      form: {},
      settleForm: {},
      countodwn: 59,
      validate: false,
      countIn: null
    }
  },
  destroyed() {
    this.countodwn = 59;
    this.validate = false;
    clearInterval(this.countIn);
  },
  mounted() {
    if (localStorage.getItem("ref")) {
      this.form.ref = localStorage.getItem("ref");
    }
    if (localStorage.getItem("refCode")) {
      this.settleForm.refCode = localStorage.getItem("refCode");
    }
    if (this.$route.query.status) {
      if (this.$route.query.status == 2) {
        this.step = this.$route.query.status;
        this.settleForm.phoneNumber = this.$route.query.phone;
        this.settleForm.id = this.$route.query.id;
      } else {
        if (this.$route.query.status == 3) {
          this.step = this.$route.query.status;
        }
      }
    }
  },
  
  methods: {
    back() {
      this.$router.go(-1)
    },

    async getCountDown() {
      if (!this.form.phoneNumber) {
        this.$message.error('请输入正确的手机号');
        return
      }
      clearInterval(this.countIn);
      this.countodwn = 59;
      this.validate = false
      try {
        let res = await this.$http.post('/auth/getSmsCode', {
          phoneNumber: this.form.phoneNumber
        })
        if (res.code == '200') {
          this.validate = true;
          this.countIn = setInterval(() => {
            if (this.countodwn > 1) {
              this.countodwn--
            } else {
              clearInterval(this.countIn);
              this.countodwn = 59;
              this.validate = false
            }
          }, 1000)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async register() {
      if (this.form.rePassword != this.form.password) {
        this.$message.error('两次输入密码不一致');
        return
      }
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      let { phoneNumber, password, smsCode, ref } = this.form;
      try {
        let res = await this.$http.post('/auth/registration', { phoneNumber, password, smsCode, ref });
        if (res.code == '200') {
          this.$message.success('注册成功');
          this.settleForm.id = res.id;
          this.settleForm.phoneNumber = phoneNumber;
          this.step = 2;
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async submit() {
      try {
        await this.$refs.settleForm.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      let { id, refCode, name, companySubject, businessType, provinceCityDistrict, contact, phoneNumber } = this.settleForm;
      try {
        let res = await this.$http.post('/auth/fillSettleInfo', { id, refCode, name, companySubject, businessType, provinceCityDistrict, contact, phoneNumber });
        if (res.code == '200') {
          this.$message.success('入驻申请成功');
          this.step = 3;
        }
      } catch ({ message }) {

      }
    },

    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    toLogin() {
      this.$router.push('/login')
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
  }
}
</script>

<style lang="scss" scoped>
.register {
  width: 100%;
  height: 100vh;
  background: url('../assets/img/pc-login-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .login-body {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .lb-card {
    width: 400px;
    height: 560px;
  }

  .ml-header {
    background: url('../assets/img/m-login-bg.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 180px;
    padding: 16px 0;
  }

  .ant-input-affix-wrapper {
    border-color: #DCDCDC;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 11px 20px;
    border-radius: 40px;
  }

  .ant-btn.ant-btn-lg {
    border-radius: 40px;
  }

  .step-two {
    height: 300px;
    overflow-y: scroll;
    margin-bottom: 30px;
  }

  .mr-ve {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 40px;

    .ant-input {
      padding: 0;
      border: none;
    }
  }

  .re-code {
    color: #ffffff;
    display: inline-block;
    width: 140px;
    font-size: 12px;
    text-align: center;
    margin-left: 12px;
    height: 30px;
    line-height: 30px;
    background-color: #175DFF;
    cursor: pointer;
    border-radius: 40px;
    border: none;
  }

  .re-code-num {
    @extend .re-code;
    background-color: #C8C8C8;
  }

  .submit-success {
    width: 100%;
    margin-bottom: 50px;
  }

  :deep(.ant-form-item .ant-form-item-label>label) {
    color: #666666;
  }

  :deep(.ant-form-vertical .ant-form-item-label) {
    padding-bottom: 15px;
    color: #666666;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border: 1px solid #DCDCDC !important;
    padding: 8px 20px;
    border-radius: 40px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
    height: auto !important;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-multiple .ant-select-selection-item) {
    background: none;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-steps .ant-steps-item-title) {
    font-size: 14px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-steps .ant-steps-item-description) {
    font-size: 12px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input) {
    padding: 10px 10px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input) {
    height: 46px;
  }
}
</style>

<style lang="scss" scoped>
.m-register {
  .ant-input-affix-wrapper {
    border-color: #DCDCDC;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 11px 20px;
    border-radius: 40px;
  }

  .ant-btn.ant-btn-lg {
    border-radius: 40px;
  }

  .mr-ve {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 40px;

    .ant-input {
      padding: 0;
      border: none;
    }
  }

  .re-code {
    color: #ffffff;
    display: inline-block;
    width: 140px;
    font-size: 12px;
    text-align: center;
    margin-left: 12px;
    height: 30px;
    line-height: 30px;
    background-color: #175DFF;
    cursor: pointer;
    border-radius: 40px;
    border: none;
  }

  .re-code-num {
    @extend .re-code;
    background-color: #C8C8C8;
  }

  .submit-success {
    background: url('../assets/img/success-bg.png');
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  :deep(.ant-form-item .ant-form-item-label>label) {
    color: #666666;
  }

  :deep(.ant-form-vertical .ant-form-item-label) {
    padding-bottom: 15px;
    color: #666666;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border: 1px solid #DCDCDC !important;
    padding: 8px 20px;
    border-radius: 40px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
    height: auto !important;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-multiple .ant-select-selection-item) {
    background: none;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-steps .ant-steps-item-title) {
    font-size: 14px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-steps .ant-steps-item-description) {
    font-size: 12px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input) {
    padding: 10px 10px;
  }

  :deep(:where(.css-dev-only-do-not-override-j6gjt1).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input) {
    height: 46px;
  }
}
</style>
