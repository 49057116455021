<template>
  <div>
    <!-- 过滤 -->
    <div class="page-filter">
      <a-form ref="filterForm" :model="filterForm" layout="inline">
        <a-form-item v-for="control in filterControls" :key="control.key" :label="control.label" :name="control.key">

          <!-- 文本 -->
          <template v-if="control.type === 'text'">
            <a-input class="page-btm" :style="{ width: control.width + 'px' }"
              v-model:value.trim="filterForm[control.key]" :placeholder="`请输入${control.label}`" allow-clear />
          </template>

          <!-- 组合框 -->
          <template v-if="control.type === 'compact'">
            <a-form-item-rest>
              <a-input-group compact>
                <a-select class="page-btm" style="width :100px" v-model:value="filterInitialValue" placeholder="请选择"
                  @change="getTypeData($event, control.config.options)">
                  <a-select-option :value="item.value" v-for="(item, index) in control.config.options">{{
        item.text }}</a-select-option>
                </a-select>
                <a-select show-search class="page-btm" style="width :150px" v-model:value="filterInitialValue2"
                  placeholder="请选择" @change="getData">
                  <a-select-option v-for="option in filtertOptions ? filtertOptions : control.config2.options"
                    :key="option.id">{{
        option.name
      }}
                  </a-select-option>
                </a-select>
              </a-input-group>
            </a-form-item-rest>
          </template>

          <!-- 日期范围 -->
          <template v-else-if="control.type === 'dateRange'">
            <a-range-picker :popupClassName="$isMobile ? 'rangStyle' : ''" class="page-btm"
              :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]"
              :placeholder="['开始时间', '结束时间']" />
          </template>

          <!-- 日期范围年 -->
          <template v-else-if="control.type === 'dateRangeYear'">
            <a-range-picker :popupClassName="$isMobile ? 'rangStyle' : ''" class="page-btm"
              :style="{ width: control.width + 'px' }" picker="year" v-model:value="filterForm[control.key]"
              :placeholder="['开始时间', '结束时间']" />
          </template>

          <!-- 日期 -->
          <template v-else-if="control.type === 'date'">
            <a-date-picker :disabled-date="control.disabledDate ? disabledDate : () => { }" class="page-btm"
              :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]" placeholder="全部"
              :picker="control.config.picker" />
          </template>
          <!-- 日期时间 -->
          <template v-else-if="control.type === 'datetime'">
            <a-date-picker class="page-btm" :style="{ width: control.width + 'px' }" show-time
              v-model:value="filterForm[control.key]" placeholder="全部" />
          </template>

          <!-- 数值 -->
          <template v-else-if="control.type === 'numeric'">
            <a-input-number class="page-btm" :style="{ width: control.width + 'px' }"
              v-model:value="filterForm[control.key]" placeholder="全部" />
          </template>

          <!-- 下拉选择 -->
          <template v-else-if="control.type === 'select'">
            <a-select class="page-btm" :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]"
              placeholder="全部" allow-clear :filter-option="filterOptionSelect" show-search>
              <a-select-option v-for="option in control.config.options" :key="option.value" :name="option.text">{{
        option.text
      }}
              </a-select-option>
            </a-select>
          </template>

          <!-- 下拉搜索选择 -->
          <template v-else-if="control.type === 'souSelect'">
            <a-select class="page-btm" :filter-option="filterSouOption" show-search
              :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]" placeholder="全部"
              allow-clear>
              <a-select-option v-for="option in control.config.options" :key="option.value" :text="option.text">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </template>

          <!-- 下拉多选选择 -->
          <template v-else-if="control.type === 'mSelect'">
            <a-select class="page-btm" :style="{ width: control.width + 'px' }" v-model:value="filterForm[control.key]"
              :placeholder="getPlaceholder(control)" mode="multiple" allow-clear show-search
              :filter-option="filterOption" @change="getSelect($event, control)">
              <a-select-option v-for="option in control.config.options" :key="option.value" :name="option.text"
                :disabled="option.disabled">{{
        option.text }}
              </a-select-option>
            </a-select>
          </template>

          <!-- 树状下拉选择 -->
          <template v-else-if="control.type === 'treeSelect'">
            <a-tree-select class="page-btm" v-model:value="filterForm[control.key]"
              :style="{ width: control.width + 'px' }" allow-clear :tree-data="control.config.options"
              tree-default-expand-all placeholder="全部">
            </a-tree-select>
          </template>
          <!-- 级联选择 -->
          <template v-else-if="control.type === 'cascader'">
            <a-cascader class="page-btm" v-model:value="filterForm[control.key]" change-on-select
              :show-search="{ filter }" :options="control.config.options" placeholder="全部" />
          </template>
        </a-form-item>
        <a-form-item v-if="filterControls.length !== 0">
          <a-button type="primary" html-type="submit" :loading="filterSubmitLoading" @click="handleFilterSubmit">
            <template #icon>
              <SearchOutlined />
            </template>
            查询</a-button>
        </a-form-item>
        <a-form-item v-if="filterControls.length !== 0" @click="resetFilterSubmit">
          <a-button>重置</a-button>
        </a-form-item>
      </a-form>
    </div>

    <!-- 操作 -->
    <div style="display: flex; align-items: center; flex-wrap: wrap; margin-top: 10px;">
      <div v-if="config.belongTo" style="margin-right: 30px;">{{ config.belongTo }}</div>
      <div class="page-actions">
        <!-- <a-space> -->
        <div v-for="action in noPermissionActions" style="display: inline-block;">
          <div v-if="action.needPermission && (action.showPermission(institutionsName))" style="display: inline-block;">
            <component style="margin-right: 20px; margin-bottom: 10px;"
              :class="[action.needVerfity ? (action.hiddenCondition(tableData) ? 'hidden-block' : '') : '']"
              :key="action.key" :is="getActionComponent(action)" type="button" :action="action"
              :disabledValue="rowActionVisible(action, tableData)" :actionText="textActions(action, tableData)"
              :listFilterKey="config.listFilter ? listFilterKey[config.listFilter.key] : null" :filterForm="filterForm"
              @change="change" :selected-rows="selectedRows" />
          </div>
          <div v-if="!action.needPermission">
            <component style="margin-right: 20px; margin-bottom: 10px;"
              :class="[action.needVerfity ? (action.hiddenCondition(tableData) ? 'hidden-block' : '') : '']"
              :key="action.key" :is="getActionComponent(action)" type="button" :action="action"
              :disabledValue="rowActionVisible(action, tableData)" :actionText="textActions(action, tableData)"
              :listFilterKey="config.listFilter ? listFilterKey[config.listFilter.key] : null" :filterForm="filterForm"
              @change="change" :selected-rows="selectedRows" />
          </div>
        </div>

        <!-- </a-space> -->
      </div>
    </div>

    <!-- 统计筛选 -->
    <div style="margin-top: 10px;" v-if="config.listFilter">
      <a-radio-group v-model:value="listFilterKey[config.listFilter.key]" @change="statisticsChange">
        <a-radio-button v-for="(item, index) in config.listFilter.options" :value="item.value">{{ item.name
          }}</a-radio-button>
      </a-radio-group>
    </div>

    <!-- 表格 -->
    <div class="page-table">
      <div v-if="config.treeFilter" :class="[!$isMobile ? 'tree-filter' : 'tree-filter-mobile']">
        <div v-for="control in config.treeFilter.controls">
          <a-tree v-if="control.type === 'tree'" v-model:selectedKeys="treeFilterForm[control.key]"
            class="draggable-tree" @select="(e) => selectTreeNode(e, control.key)" block-node
            :tree-data="control.config.options" />
        </div>
      </div>

      <a-table :class="[($isMobile && config.treeFilter) ? 't-width-mobile' : 't-width']" :scroll="config.scroll"
        :columns="tableColumns" :data-source="tableData" :loading="tableLoading" size="small" bordered
        :row-selection="config.checkbox ? { selectedRowKeys: tableSelectedRowKeys, onChange: handleTableRowSelect } : null"
        :rowKey="record => record.id" :pagination="config.pagination === false ? false : tablePagination"
        @change="handleTableChange">
        <!-- title加入popover提示 -->
        <template #headerCell="{ column }">
          <div class="resizable-header" :style="{ resize: column.resize ? 'horizontal' : 'none' }">
            <template v-if="column.popover">
              <span>{{ column.title }}</span>
              <a-popover title="">
                <template #content>
                  <p>{{ column.popover }}</p>
                </template>
                <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
              </a-popover>
            </template>
            <template v-else>
              <span>{{ column.title }}</span>
            </template>
            <div class="resize-handle"></div>
          </div>
        </template>
        <template #bodyCell="{ column, record, text }">
          <!-- 文本 -->
          <template v-if="column.type === 'text'">
            <span v-if="!column.ellipsis">
              {{ getArrSpliComma(column.key, record) }}
            </span>
            <a-tooltip v-else placement="topLeft" :title="getArrSpliComma(column.key, record)">
              <span>{{ getArrSpliComma(column.key, record) }}</span>
            </a-tooltip>
          </template>
          <!-- a-popover -->
          <template v-if="column.type === 'popover'">
            <a-tooltip placement="topLeft" :title="getArrSpliComma(column.key, record)" arrow-point-at-center>
              <p class="ellipsis">{{ getArrSpliComma(column.key, record) }}</p>
            </a-tooltip>
          </template>
          <!-- 编辑 -->
          <template v-else-if="column.type === 'edit'">
            <div class="editable-cell">
              <div v-if="editableData[record.id]" class="editable-cell-input-wrapper">
                <a-input v-model:value="editableData[record.id][column.key]" />

                <a-select v-model:value="s" mode="multiple" allow-clear>
                  <a-select-option name="2">
                  </a-select-option>
                </a-select>
                <check-outlined class="editable-cell-icon-check" @click="save(record.id)" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ getArrSpliComma(column.key, record) || ' ' }}
                <edit-outlined class="editable-cell-icon" @click="edit(record.id)" />
              </div>
            </div>
          </template>
          <template v-if="column.type === 'textList'">
            {{ getArrStrComma(column.ckey, record[column.key]) }}
          </template>
          <!-- 自定义文本 -->
          <template v-if="column.type === 'customizeText'">
            <span>{{ column.customize(record) }}</span>
          </template>
          <!-- 图片 -->
          <template v-if="column.type === 'image'">
            <a-image style="max-width:50px;max-height:50px" :src="record[column.key]" />
          </template>
          <!-- 日期 -->
          <template v-else-if="column.type === 'datetime'">
            <span>{{ getYMDMoment(getArrSpliComma(column.key, record)) }}</span>
          </template>
          <!-- 日期时间 -->
          <template v-else-if="column.type === 'date'">
            <span>{{ getYMDHMSMoment(getArrSpliComma(column.key, record)) }}</span>
          </template>
          <!-- 枚举 -->
          <template v-else-if="column.type === 'enum'">
            <span v-if="column.config != null">
              <a-tag style="margin: 0;"
                :color="column.config.constants[getArrSpliComma(column.key, record)] && column.config.constants[getArrSpliComma(column.key, record)].color">
                {{ column.config.constants[getArrSpliComma(column.key, record)] &&
        column.config.constants[getArrSpliComma(column.key, record)].text }}</a-tag>
            </span>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 自定义 -->
          <template v-else-if="column.type === 'textCustom'">
            <div v-if="column.config != null">
              <span>{{ column.config.text(record[column.key]) }}</span>
            </div>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 可跳转 -->
          <template v-if="column.type === 'link'">
            <a-button @click="jump(column, record)" type="link">{{ column.config.text(record[column.key]) }}</a-button>
          </template>
          <!-- color -->
          <template v-else-if="column.type === 'color'">
            <span v-if="record[column.key] != null">
              <a-tag class="tag-color"
                :style="{ backgroundColor: bgColor(getArrSpliComma(column.config.colorkey, item)), border: borderColor(getArrSpliComma(column.config.colorkey, item)), color: getArrSpliComma(column.config.colorkey, item) }"
                v-for="(item, index) in record[column.key]" :color="getArrSpliComma(column.config.colorkey, item)">
                {{ getArrSpliComma(column.config.textkey, item) }}
              </a-tag>
            </span>

          </template>
          <!-- 枚举 -->
          <template v-else-if="column.type === 'textEnum'">
            <div v-if="column.config != null">
              <span>{{ column.config.constants[record[column.key]] }}</span>
            </div>
            <span v-else class="text-muted">无</span>
          </template>
          <!-- 数值 -->
          <template v-else-if="column.type === 'numeric'">
            <span v-if="record[column.key] != null">
              <template v-if="column.config.format === 'percent'">
                {{ $formatCurrency(record[column.key]) }}
              </template>
              <template v-else-if="column.config.format === 'currency'">
                {{ $formatCurrency(record[column.key]) }}
              </template>
              <template v-else>{{ record[column.key] }}</template>
            </span>
            <span v-else class="text-muted">无</span>
          </template>

          <!-- 操作 -->
          <template v-else-if="column.type === 'actions'">
            <template v-for="(action, index) in noPermissionRowActions">

              <div v-if="action.needPermission && (action.showPermission(institutionsName))"
                style="display: inline-block;">
                <a-divider
                  v-if="index > 0 && noPermissionRowActions.findIndex(val => !val.disabled || (!val.disabled && rowActionVisible(val, [record]))) !== index"
                  type="vertical" />
                <template v-if="Array.isArray(action)">
                  <a-dropdown>
                    <a class="ant-dropdown-link">更多
                      <down-outlined />
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item v-for="childAction in action" :key="childAction.key">
                        <component v-if="!childAction.disabled" :is="getActionComponent(childAction)" type="link"
                          :action="childAction" :disabledValue="rowActionVisible(childAction, [record])"
                          :selected-rows="[record]" />
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>
                <component v-else :is="getActionComponent(action)" type="link" :action="action"
                  :disabledValue="rowActionVisible(action, [record])" :selected-rows="[record]" @change="change" />
              </div>
              <div v-if="!action.needPermission" style="display: inline-block;">
                <a-divider
                  v-if="index > 0 && noPermissionRowActions.findIndex(val => !val.disabled || (!val.disabled && rowActionVisible(val, [record]))) !== index"
                  type="vertical" />
                <template v-if="Array.isArray(action)">
                  <a-dropdown>
                    <a class="ant-dropdown-link">更多
                      <down-outlined />
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item v-for="childAction in action" :key="childAction.key">
                        <component v-if="!childAction.disabled" :is="getActionComponent(childAction)" type="link"
                          :action="childAction" :disabledValue="rowActionVisible(childAction, [record])"
                          :selected-rows="[record]" />
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>
                <component v-else :is="getActionComponent(action)" type="link" :action="action"
                  :disabledValue="rowActionVisible(action, [record])" :selected-rows="[record]" @change="change" />
              </div>
            </template>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-table {
  margin-top: 12px;
  background: #fff;
  display: flex;
}

.tree-filter {
  width: 18%;
  padding: 20px;
}

.tree-filter-mobile {
  width: 34%;
  padding: 6px 0;
  // overflow-x: scroll;
}

.t-width {
  width: 100%;
}

.t-width-mobile {
  width: 66%;
}

.page-actions {
  margin-top: 12px;
}

.page-btm {
  margin-bottom: 10px;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tree-input {
  margin-right: 10px;
}

.ant-table-cell .inline :deep(.ant-btn) {
  padding: 0 !important;
}

:deep(.ant-table-content) {
  overflow-x: auto;
  width: 100%;
}

// 滚动条的宽度
:deep(.ant-table-content::-webkit-scrollbar) {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.ant-table-content::-webkit-scrollbar-thumb) {
  background-color: #ddd;
  border-radius: 3px;
}

:deep(.ant-table-content::-webkit-scrollbar) {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}

.tag-color {
  border: 2px solid #ccc;
}

.editable-cell {
  position: relative;

  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.hidden-block {
  display: none;
}
</style>
<style lang="scss">
.rangStyle {
  .ant-picker-panels {
    display: inline-flex;
    flex-wrap: nowrap;
    direction: ltr;
    flex-direction: column;
  }
}

.resizable-header {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  // border-right: 1px solid #ddd;  
  // resize: horizontal; /* 允许水平拖动调整大小 */
}

.ellipsis {
  max-width: 130px;
  min-width: 60px;
  overflow: hidden;
  /* 确保超出容器的文本被裁剪 */
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  text-overflow: ellipsis;
  /* 使用省略号表示文本超出 */
}
</style>

<script>
import FormAction from "@/components/FormAction";
import ConfirmAction from "@/components/ConfirmAction";
import BlankAction from "@/components/BlankAction";
import Desensitization from "@/components/list/Desensitization";
import UrlAction from "@/components/UrlAction";
import DialogAction from "@/components/DialogAction";
import tree from "@/utils/tree";
import dayjs from "dayjs";
import { getProfile } from "@/utils/session";
import { cloneDeep } from 'lodash-es';
import chroma from 'chroma-js';
export default {
  name: "TablePage",
  components: { UrlAction, BlankAction, ConfirmAction, DialogAction, FormAction, Desensitization },
  props: {
    config: Object
  },

  data() {
    return {
      noPermissionRowActions: [],
      noPermissionActions: [],
      editableData: {},
      filtertOptions: null,
      filterInitialValue: null,
      filterInitialValue2: null,
      isSpecialInstitutions: null,
      filterForm: {},
      treeFilterForm: {},
      filterInitData: {},
      filterSubmitLoading: false,
      tableLoading: false,
      tableData: [],
      tableRowKey: "id",
      tableSelectedRowKeys: [],
      tableSort: this.config.sorter?.defaultSort,
      tablePagination: {
        showSizeChanger: false,
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: total => `共 ${total} 条`,
        showSizeChanger: true, // 选择每页条数开关
        pageSizeOptions: ['10', '20', '50', '100']
      },
      listFilterKey: {},
      institutionsName: null
    };
  },
  created() {
    // if (this.config.filter.initUrl != null) {
    //   this.loadFilterInitData();
    // }
    // this.loadTableData();
  },
  mounted() {
    this.getProfile();

    if (this.config.listFilter) {
      if (this.$route.query.select) {
        this.listFilterKey[this.config.listFilter.key] = this.$route.query.select;
      } else {
        this.listFilterKey[this.config.listFilter.key] = this.config.listFilter.defaultValue;
      }
    }

    if (this.config.filter.initUrl != null) {
      this.loadFilterInitData();
    }
    this.loadTableData();
  },
  computed: {
    /**
     * 下拉选择数据
     */
    treeSelectData() {
      if (this.filterInitData == null) {
        return {};
      }
      return this.filterControls.filter(({ type }) => type === 'treeSelect').reduce((prev, control) => {
        prev[control.key] = tree(this.filterInitData[control.config.options]);
        return prev;
      });
    },

    /**
     * 过滤表单项
     */
    filterControls() {
      return this.config.filter.controls.map(control => {
        if (control.type === 'select' || control.type === 'mSelect' || control.type === 'souSelect') {
          let updatedOptions = typeof control.config.options === 'string'
            ? (this.filterInitData[control.config.options] ?? []).map(item => ({
              text: item[control.config.textKey ?? 'name'],
              value: item[control.config.valueKey ?? 'id']
            }))
            : control.config.options?.map(option => ({
              ...option,
              value: (typeof option.value === 'boolean') ? String(option.value) : option.value
            }));
          if (control.config.addOptions) {
            updatedOptions = updatedOptions.concat(control.config.addOptions);
          }
          return {
            ...control,
            width: 150,
            config: {
              ...control.config,
              options: updatedOptions
            }
          };
        } else if (control.type === 'treeSelect') {
          return {
            ...control, width: 100,
            config: {
              options: typeof control.config.options === 'string'
                ? tree(this.filterInitData[control.config.options] ?? [], {
                  transform(node) {
                    const value = node[control.config.valueKey ?? 'id'];
                    return {
                      title: node[control.config.textKey ?? 'name'],
                      value,
                      key: value
                    };
                  }
                }) : control.config.options,

            }
          }
        } else if (control.type === 'date') {
          if (control.config && control.config.initialValue == '上月') {
            if (!this.filterForm[control.key]) {
              const today = dayjs();
              const lastMonth = today.subtract(1, 'month');
              this.filterForm[control.key] = lastMonth
            }

          }
          return {
            ...control,
            width: 140
          }
        } else if (control.type === 'compact') {
          if (control.config.initialValue && !this.filterInitialValue) {
            this.filterInitialValue = control.config.initialValue
          }
          let options = this.getCompactData(this.filterInitData, control.config2)
          return {
            ...control,
            width: 140,
            config2: {
              ...control.config2,
              options
            }
          }
        } else if (control.type === 'dateRange') {
          if (control.type === 'dateRange') {
            if (control.config && control.config.initialValue == '当月') {
              let today = dayjs().format('YYYY-MM-DD')
              let firstDay = today.split('-')[0] + '-' + today.split('-')[1] + '-01'
              firstDay = dayjs(firstDay).format('YYYY-MM-DD' + ' 00:00:00')
              today = dayjs(today).format('YYYY-MM-DD' + ' 23:59:59')
              let month = []
              month[1] = dayjs(today)
              month[0] = dayjs(firstDay)
              this.filterForm[control.key] = month
            }
          }
          return {
            ...control,
            width: 230
          }
        } else if (control.type === 'dateRangeYear') {
          return {
            ...control,
            width: 230
          }
        } else {
          return {
            ...control,
            width: 140
          }
        }
      });
    },

    /**
     * 表格列
     */
    tableColumns() {
      let columns = this.config.columns.map(column => {
        if (column.columnsHidden && typeof column.columnsHidden === 'function') {
          column.colHidden = column.columnsHidden(this.isSpecialInstitutions)
        }
        return column
      })
      let filterColumns;
      if (this.noPermissionRowActions.length == 0) {
        filterColumns = columns.filter(column => !column.colHidden && column.type !== 'actions');
      } else {
        filterColumns = columns.filter(column => !column.colHidden);
      }
      return filterColumns.map(column => {
        return {
          ...column,
          type: column.type ?? 'text',
          config: column.config ?? {}
        }
      });
    },

    /**
     * 操作
     */
    actions() {
      return this.config.actions;
    },

    /**
     * 表格行操作
     */
    rowActions() {
      return this.config.rowActions ?? [];
    },

    /**
     * 表格数据 Map
     */
    tableDataMap() {
      return this.tableData.reduce((prev, row) => {
        prev[row[this.tableRowKey]] = row;
        return prev;
      }, {});
    },

    /**
     * 选择的表格行
     */
    selectedRows() {
      return this.tableSelectedRowKeys.map(key => this.tableDataMap[key]);
    },

    /**
     * 可排序列
     */
    sortableColumns() {
      return new Set(this.config.sorter?.sortableColumns);
    }
  },

  methods: {
    filterOptionSelect(input, option) {
      return option.name.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    getSelect(value, control) {
      if (control.limitNum && value.length > control.limitNum) {
        value.pop();
        this.$message.error(`最多只能选择${control.limitNum}个`);
      }
    },
    getPlaceholder(control) {
      return (['date', 'datetime', 'select', 'mSelect', 'dateRange', 'dateRangeYear'].includes(control.type) ? '选择' : control.type === 'file' ? '上传' : '输入') + control.label;
    },
    bgColor(color) {
      return chroma(color).brighten(2).hex();
    },
    borderColor(color) {
      return chroma(color).brighten(-0.5).hex();
    },
    edit(key) {
      this.editableData[key] = cloneDeep(this.tableData.filter(item => item.id === key)[0]);
    },
    save(key) {
      Object.assign(this.dataSource.filter(item => item.id === key)[0], this.editableData[key]);
      delete this.editableData[key];
    },
    disabledDate(current) {
      return current && current > dayjs().endOf('day');
    },
    getCompactData(data, control) {
      let updatedOptions = []
      if (typeof control.options === 'string') {
        updatedOptions = (data[control.options] ?? []).map(item => ({
          name: item[control.textKey ?? 'name'],
          id: item[control.valueKey ?? 'id']
        }))
      }
      return updatedOptions
    },
    getData(val) {
      let key = this.filterInitialValue
      // this.filterForm[key] = this.filterInitialValue2
    },
    async getTypeData(val, data) {
      this.filterInitialValue = val
      this.filterInitialValue2 = null
      const obj = data.find(item => item.value === val);
      const url = obj.url;
      try {
        const res = await this.$http.get(url)
        if (res.code == '200') {
          this.filtertOptions = res.list
        }
      } catch (error) {

      }
    },
    async getProfile() {
      try {
        let profile = await getProfile();
        this.institutionsName = profile.institutions.id;
        this.isSpecialInstitutions = profile.institutions.id;
        let actions = this.actions.filter(action => {
          if (profile.everyPermissions(action.permission)) {
            return action
          }
        });
        let rowActions = this.rowActions.filter(action => {
          if (profile.everyPermissions(action.permission)) {
            return action
          }
        }
        );
        this.noPermissionRowActions = rowActions
        this.noPermissionActions = actions
      } catch ({ message, status }) {
        if (status !== 401) {
          this.$message.error(message);
        }
      }
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    textActions(action, row) {
      // console.log(action, row);
      if (typeof action.text === 'function') {
        return action.text({
          selectedRows: row,
          filterForm: this.filterForm
        });
      } else {
        return action.text
      }
    },
    rowActionVisible(action, row) {
      if (action.disabled == null) {
        return true;
      }
      if (typeof action.disabled === 'function') {
        return !action.disabled({
          selectedRows: row,
          filterForm: this.filterForm,
          institutionsName: this.isSpecialInstitutions
        });
      }

      // console.log(action.disabled);
      // return !action.disabled({
      //   selectedRows: row
      // });
    },
    /**
     * 确认完成
     */
    change() {
      this.loadTableData({
        resetPage: false
      })
    },
    /**
     * 加载过滤初始数据
     */
    async loadFilterInitData() {
      if (this.config.filter.initUrl != null) {
        this.filterInitData = await this.$http.get(this.config.filter.initUrl);
        if (this.config.treeFilter && this.config.treeFilter.controls) {
          this.config.treeFilter.controls.map(control => {
            if (control.type === 'tree') {
              control.config.options = tree(this.filterInitData[control.config.optList] ?? [], {
                transform(node) {
                  const value = node[control.config.valueKey ?? 'id'];
                  return {
                    title: node[control.config.textKey ?? 'name'],
                    value,
                    key: value
                  };
                }
              })
            }
          })
        }
      }
    },

    /**
     * 处理过滤表单查询
     */
    async handleFilterSubmit() {
      this.filterSubmitLoading = true;
      await this.loadTableData({
        resetPage: true
      });
      this.filterSubmitLoading = false;
    },

    /**
     * 重置表单查询
     */
    async resetFilterSubmit() {
      this.filterSubmitLoading = true;
      this.treeFilterForm = {};
      this.filterInitialValue = null,
        this.filterInitialValue2 = null,
        await this.loadTableData({
          resetPage: true,
          resetFilter: true
        });
      this.filterSubmitLoading = false;
    },

    /**
     * 处理表格排序、分页信息改变
     */
    handleTableChange(tablePagination, filters, sorter) {
      this.tableSort = sorter.order == null ? this.config?.sorter?.defaultSort : (sorter.order === 'ascend' ? '' : '-') + sorter.columnKey;
      this.tablePagination = {
        ...this.tablePagination,
        current: tablePagination.current,
        pageSize: tablePagination.pageSize
      };
      this.loadTableData();
    },

    statisticsChange(e) {
      this.handleFilterSubmit()
    },

    /**
     * 加载表格数据
     */
    async loadTableData(options) {
      options = {
        resetPage: false,
        ...options
      };
      this.tablePagination = {
        ...this.tablePagination,
        current: options.resetPage ? 1 : this.tablePagination.current
      };
      let methods = 'get'
      if (this.config.methods) {
        methods = this.config.methods
      }
      if (this.config.dataUrl != null) {
        this.tableLoading = true;
        try {
          let res = await this.$http[methods](this.config.dataUrl, {
            ...this.getFilterForm(options),
            ...this.treeFilterForm,
            sort: this.tableSort,
            page: this.tablePagination.current,
            size: this.tablePagination.pageSize,
            quickCheck: this.config.listFilter ? this.listFilterKey[this.config.listFilter.key] : null
          });
          const rows = res.rows;
          const data = res.data;

          this.tableData = this.config.tree != null ? tree(data, {
            parent: this.config.tree
          }) : data;
          if (this.config.tree != null) {
            this.tableData.map((item) => {
              this.config.tree != null ? item.key = item.id : ''
            })
          }
          // 合并表格系列
          if (this.config.rowSpan) {
            const result = [];
            this.tableData && this.tableData.forEach(item => {
              const keys = Object.keys(item);
              item.detailList.forEach((subItem, index) => {
                const newObj = {};
                keys.forEach(key => {
                  newObj[key] = item[key];
                });
                for (const key in subItem) {
                  newObj[`detailList_${key}`] = subItem[key];
                }
                newObj.rowSpan = index === 0 ? item.detailList.length : 0;
                result.push(newObj);
              });
            });
            this.tableData = result
          }

          if (this.config.dealsort) {
            // 将商机流失移动到最后一个位置
            const lostIndex = this.tableData.findIndex(item => item.content === '商机流失');
            if (lostIndex !== -1) {
              const lostItem = this.tableData.splice(lostIndex, 1)[0];
              this.tableData.push(lostItem);
            }
            // 将商机成交移动到倒数第二个位置
            const dealIndex = this.tableData.findIndex(item => item.content === '商机成交');
            if (dealIndex !== -1) {
              const dealItem = this.tableData.splice(dealIndex, 1)[0];
              this.tableData.splice(this.tableData.length - 1, 0, dealItem);
            }
          }

          this.tablePagination = { ...this.tablePagination, total: rows };
        } catch ({ message }) {
          this.$message.error(message);
        }
        this.tableLoading = false;
        return;
      }

      // 静态数据
      if (this.config.data != null) {
        this.tableData = this.config.data;
      }
    },

    /**
     * 处理表格行选择
     */
    handleTableRowSelect(selectedRowKeys) {
      this.tableSelectedRowKeys = selectedRowKeys;
    },

    /**
     * 获取过滤表单
     */
    getFilterForm(options) {
      if (!options.resetFilter) {
        return this.filterControls.reduce((prev, control) => {
          const value = this.filterForm[control.key];
          if (value != null) {
            if (control.type === 'dateRange') {
              prev[`${control.key}OnOrAfter`] = dayjs(value[0]).format('YYYY-MM-DD') + ' 00:00:00';
              prev[`${control.key}OnOrBefore`] = dayjs(value[1]).format('YYYY-MM-DD') + ' 23:59:59';
            } else if (control.type === 'dateRangeYear') {
              prev[`${control.key}OnOrAfter`] = dayjs(value[0]).format('YYYY');
              prev[`${control.key}OnOrBefore`] = dayjs(value[1]).format('YYYY');
            } else if (control.type === 'date') {
              if (control.config.picker == 'month') {
                prev[control.key] = dayjs(value).format('YYYY-MM');
              } else {
                prev[control.key] = dayjs(value).format('YYYY-MM-DD') + ' 00:00:00';
              }
            } else if (control.type === 'datetime') {
              prev[control.key] = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
            } else {
              prev[control.key] = value;
            }
          }
          if (control.type === 'compact') {
            let key = this.filterInitialValue
            prev[key] = this.filterInitialValue2
          }
          return prev;
        }, {});
      } else {
        return this.filterControls.reduce((prev, control) => {
          if (control.noResetFilter) {
            const value = this.filterForm[control.key];
            if (value != null) {
              if (control.type === 'dateRange') {
                prev[`${control.key}OnOrAfter`] = dayjs(value[0]).format('YYYY-MM-DD') + ' 00:00:00';
                prev[`${control.key}OnOrBefore`] = dayjs(value[1]).format('YYYY-MM-DD') + ' 23:59:59';
              } else if (control.type === 'dateRangeYear') {
                prev[`${control.key}OnOrAfter`] = dayjs(value[0]).format('YYYY');
                prev[`${control.key}OnOrBefore`] = dayjs(value[1]).format('YYYY');
              } else if (control.type === 'date') {
                if (control.config.picker == 'month') {
                  if (control.config.initialValue == '上月') {
                    const today = dayjs();
                    const lastMonth = today.subtract(1, 'month');
                    prev[control.key] = dayjs(lastMonth).format('YYYY-MM')
                    this.filterForm[control.key] = lastMonth
                  } else {
                    prev[control.key] = dayjs(value).format('YYYY-MM');
                  }
                } else {
                  prev[control.key] = dayjs(value).format('YYYY-MM-DD') + ' 00:00:00';
                }
              } else if (control.type === 'datetime') {
                prev[control.key] = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
              } else {
                prev[control.key] = value;
              }
            }
            if (control.type === 'compact') {
              let key = this.filterInitialValue
              prev[key] = this.filterInitialValue2
            }
            return prev;
          } else {
            for (let key in this.filterForm) {
              if (typeof (this.filterForm[key]) === 'object') {
                this.filterForm[key] = [];
              } else {
                this.filterForm[key] = null;
              }
            }
            return {}
          }
        }, {});

        // for (let key in this.filterForm) {
        //   this.filterForm[key] = null
        // };
        // return {}
      }
    },

    /**
     * 指定列是否可排序
     */
    sortable(column) {
      return this.sortableColumns.has(column.key);
    },

    /**
     * 指定列默认排序方向
     */
    defaultSortOrder(column) {
      return this.tableSort?.replace(/^-/, '') !== column.key ? null
        : this.tableSort.startsWith('-') ? 'descend' : 'ascend';
    },

    /**
     * 获取操作组件名
     */
    getActionComponent(action) {
      return action.type + '-action';
    },
    // 年月日
    getYMDMoment(value) {
      if (value) {
        return dayjs(value).format('YYYY-MM-DD')
      } else {
        return '无'
      }
    },
    //年月日 时分秒
    getYMDHMSMoment(value) {
      if (value) {
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return '无'
      }
    },
    //列表多查看人逗号分隔
    getArrStrComma(val, itemArr) {
      if (itemArr) {
        let ckey = val.split('.')
        let allItem = ''
        itemArr.map((item, index) => {
          let comma = itemArr.length <= index + 1 ? '' : '，'
          const value = ckey.reduce((obj, key) => obj[key], item);
          allItem += value + comma
        })
        return allItem
      }
    },
    //得到嵌套对象
    getArrSpliComma(val, allItem) {
      let ckey = val.split('.')
      for (let i = 0; i < ckey.length; i++) {
        if (allItem[ckey[0]] !== null) {
          allItem = allItem[ckey[i]];
        } else {
          allItem = ''
        }
      }
      return allItem
    },

    //树节点点击事件
    selectTreeNode(e, key) {
      // this.treeFilterForm[key] = e[0];
      this.handleFilterSubmit();
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },

    //是否跳转
    jump(column, row) {
      this.$router.push(column.config.path + '?id=' + row.id + '&other=' + row[column.config.query])
    }
  }
}
</script>
