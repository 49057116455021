export default {
  key: '/store/userEvaluate',
  title: '用户评价',
  permission: '/clientUser/userEvaluation/getList',
  permissionTit: '用户评价',
  config: {
      component: () => import("./views/userEvaluateDetail"),
      actions: [
        {
          text: '新建用户评价',
          permission: '/clientUser/userEvaluation/insert',
        },
        {
          text: '删除',
          permission: '/clientUser/userEvaluation/delete',
        }
      ],
      rowActions: [
        {
          text: '编辑',
          permission: '/clientUser/userEvaluation/update',
        }
      ]
  }
} 